<!--
 * @Author: Dyf
 * @LastEditors: Please set LastEditors
 * @Date: 2023-03-29 09:31:14
 * @LastEditTime: 2024/04/25
 * @Descripttion: 【教学】备课
-->
<style lang="scss" scoped>
.prepare {
  @include innerPage;
  @include pageHead(-10px);
  @include pageFoot;
  padding: 0;
  margin-left:70px;
  &.width100{
    width: 100%;
    margin: 0;
  }
  .page-head {
    .head-wrapper.column {
      margin-top: -24px;
    }

    .search {
      margin-right: 68px;
      height: 48px;
      line-height: 48px;

      .iconfont-color {
        display: inline-flex;
        width: 48px;
        height: 48px;
        border-radius: 50%;
        background: rgba($color: #6340c8, $alpha: 0.09);
        align-items: center;
        justify-content: center;
        font-size: 24px;
        margin-right: 10px;
      }

      .iconfont {
        margin-left: 8px;
      }
    }
  }

  .page-inner {
    width: 100%;
    height: calc(100% - 162px);
    box-sizing: border-box;
    padding-top: 24px;

    .class-hours {
      width: 506px;
      height: 60px;
      border-radius: 10px;
      background: #fff;
      margin-bottom: 16px;
      @include flexBox;

      ::v-deep {
        .el-button {
          &--text {
            height: 60px;
            line-height: 60px;
            padding: 0 30px;
          }
        }
      }

      .select-time {
        flex-grow: 1;
        height: 60px;
        margin-left: 10px;
        font-size: 16px;
        color: #474747;
        line-height: 64px;
      }

      .iconfont {
        &:first-child {
          font-size: 18px;
          color: #6340c8;
        }
      }

      span {
        margin: 0 8px 0 0px;
      }
    }
  }
  .no-search {
    width: 100%;
    height: 100%;
    border-radius: 10px;
    background: #fff;
    flex-direction: column;
    @include flexBox(center);

    img {
      width: 200px;
      margin-bottom: 20px;
    }
  }
  .plate-group {
    width: calc(100% + 20px);
    // height: calc(100% - 76px);
    box-sizing: border-box;
    padding-right: 20px;
    overflow: hidden;
    overflow-y: auto;
    flex-wrap: wrap;
    @include flexBox(space-between, flex-start);
    &.preview {
      height: auto;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      flex-wrap: nowrap;
      margin: 0;
      .plate {
        width: 100%;
        height: auto;
        max-height: 336px;
        padding-bottom: 14px;
        box-shadow: 0px 4px 10px -2px rgba(0, 0, 0, 0.0959);
        .inner-box,
        .courseware--info {
          min-height: 160px;
          .no-data--empty {
            img {
              width: 120px;
            }
            p {
              line-height: 24px;
            }
          }
        }
        .courseware--info {
          min-height: 136px;
        }
        &:not(.courseware) {
          .inner-box {
            height: auto;
            max-height: 264px;
            .list-wrapper {
              height: auto;
              max-height: 264px;
              li .title {
                width: 380px;
              }
            }
          }
        }
      }
    }
    .plate {
      width: calc((100% - 24px) / 2);
      height: 322px;
      border-radius: 10px;
      background: #fff;
      margin-bottom: 24px;
      overflow: hidden;
      @include contentNoData;

      &:not(.courseware) {
        .inner-box {
          width: 100%;
          height: calc(100% - 72px);
          box-sizing: border-box;
          padding: 12px 48px;

          .list-wrapper {
            width: calc(100% + 32px);
            height: 100%;
            box-sizing: border-box;
            padding-right: 32px;
            overflow: hidden;
            overflow-y: auto;

            li {
              height: 60px;
              font-size: 16px;
              color: #2e2e2e;
              box-sizing: border-box;
              border-top: 1px dashed rgba($color: #2e2e2e, $alpha: 0.17);
              overflow: hidden;
              @include flexBox(space-between);
              .title {
                display: flex;
                align-items: center;
                width: 430px;
                p {
                  flex: 1;
                }
              }
              &:first-child {
                border-top: none;
              }
            }
          }

          .homework-title {
            height: 60px;
            box-sizing: border-box;
            border-bottom: 1px dashed rgba($color: #2e2e2e, $alpha: 0.17);
            @include flexBox;

            p {
              flex-grow: 1;
              font-size: 16px;
              color: #2e2e2e;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
            }
          }

          .homework-desc {
            width: calc(100% + 32px);
            height: calc(100% - 78px);
            box-sizing: border-box;
            padding-right: 32px;
            overflow: hidden;
            overflow-y: auto;
            margin-top: 18px;
            line-height: 30px;
            color: #2e2e2e;
            word-break: break-word;
          }

          .btns {
            flex-shrink: 0;
            margin-left: 10px;
          }
        }
      }
      &.public-prepare {
        margin-bottom: 0;
        width: 100%;
        height: 500px;
        .prepare-from {
          width: 264px;
          height: 68px;
          position: relative;
          margin-left: 60px;
          @include flexBox;

          &:after {
            content: "";
            width: 38px;
            height: 5px;
            border-radius: 2.5px;
            background: #58419c;
            position: absolute;
            left: 47px;
            bottom: 0;
            @include defaultAni;
          }

          &.platform::after {
            transform: translateX(132px);
          }

          li {
            width: 132px;
            cursor: pointer;
            color: #373639;
            padding: 0 52px;

            &:last-child {
              padding-left: 52px;
              position: relative;

              &::after {
                content: "";
                height: 20px;
                border-left: 1px solid rgba($color: #7a7a7a, $alpha: 0.2);
                position: absolute;
                left: 0;
                top: 50%;
                transform: translateY(-50%);
              }
            }

            &.current,
            &:not(.current):hover {
              color: #6c4ecb;
            }
          }
        }
        .inner-outer {
          width: 100%;
          height: calc(100% - 72px);
          box-sizing: border-box;
          padding: 20px 48px;
          .inner-box {
            width: 100%;
            height: calc(100% - 84px);
            margin-bottom: 10px;
            padding: 0;
            .quote-list {
              width: calc(100% + 60px);
              margin-left: -30px;
              box-sizing: border-box;
              padding: 0 30px;
              @include flexBox(space-between);
              flex-wrap: wrap;
              align-items: flex-start;
              li {
                width: 32%;
                height: 160px;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                justify-content: center;
                border-radius: 10px;
                background: #f6f7fc;
                box-sizing: border-box;
                padding: 10px 20px;
                border: none;
                .prepare-info {
                  p {
                    color: #3c3b3b;
                    line-height: 36px;
                  }
                }
                .btns {
                  margin: 18px 0 0 auto;
                }
                &:hover {
                  box-shadow: 0px 0px 20px -2px rgba(87, 87, 87, 0.15);
                }
              }
              &:after {
                content: "";
                width: 32%;
              }
            }
          }
        }
      }
    }
    .courseware {
      &-inner {
        width: 100%;
        height: calc(100% - 72px);
        box-sizing: border-box;
        padding: 24px 28px 0;

        .foot-operation {
          height: 70px;
          box-sizing: border-box;
          border-top: 1px solid rgba($color: #e1dced, $alpha: 0.8);
          @include flexBox(flex-end);

          ::v-deep .el-upload {
            &--text {
              height: 38px;
              margin-left: 10px;
            }
          }
        }
      }

      &--info {
        width: 100%;
        height: calc(100% - 70px);
        flex-direction: column;
        @include flexBox(flex-start, flex-start);

        .info-data {
          height: calc(100% - 68px);
          margin-bottom: 18px;

          .file-title {
            font-size: 18px;
            @include flexBox;
            align-items: flex-start;

            .iconfont {
              display: inline-flex;
              width: 28px;
              height: 28px;
              align-items: center;
              font-size: 28px;
              color: rgba($color: #44269a, $alpha: 0.21);
              flex-shrink: 0;
            }

            span {
              display: inline-block;
              line-height: 28px;
              flex-grow: 1;
              color: #303030;
              margin-left: 4px;
              @include ellipsisMultiline;
            }
          }

          .upload-time {
            line-height: 24px;
            color: #474747;
            box-sizing: border-box;
            padding-left: 32px;
            margin-top: 20px;
          }
        }

        .el-button {
          margin-left: auto;
        }

        .no-data--empty {
          margin-top: -10px;
        }
      }
    }
  }
  .preview-prepare {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    padding: 0 20px;
    overflow: hidden;
    overflow-y: auto;
  }
}

.popover-contianer {
  box-sizing: border-box;
  padding: 18px 5px 2px;

  ::v-deep {
    .el-form-item {
      margin-bottom: 18px;

      &:last-child {
        margin-bottom: 0;

        .el-form-item__content {
          height: 38px;
          line-height: 38px;
        }
      }
    }
  }
}

.dialog {
  &-search {
    box-sizing: border-box;
    padding: 38px 0;

    .grade {
      width: 726px;
      margin: 0 auto 44px;
      @include flexBox(space-between);

      .el-select {
        width: 360px;
      }
    }

    .curriculum {
      height: 50vh;
      max-height: 530px;
      @include flexBox(space-between);

      .edition,
      .edition-no,
      .chapter,
      .sections {
        width: calc((100% - 6px) / 4);
        height: 100%;
        border-radius: 10px;
        box-sizing: border-box;
        padding: 10px 6px 14px 30px;

        h5 {
          height: 46px;
          box-sizing: border-box;
          padding-right: 18px;
          margin-bottom: 16px;
          @include flexBox(space-between);

          span {
            flex-shrink: 0;
            margin-right: 10px;
          }
        }

        ::v-deep {
          .el-input {
            flex-grow: 1;

            &__inner {
              background: #fff;
              padding-right: 60px;
            }

            &__suffix {
              .iconfont {
                color: #6c4ecb;
              }

              .el-input__clear {
                margin-right: -50px;
                position: relative;

                &::after {
                  content: "";
                  width: 1px;
                  height: 10px;
                  left: -2px;
                  position: absolute;
                  top: 50%;
                  transform: translateY(-50%);
                  background: #b6b6b6;
                }
              }
            }
          }
        }
      }

      .edition {
        background: rgba($color: #d83131, $alpha: 0.05);
      }

      .edition-no {
        background: rgba($color: #2ac293, $alpha: 0.05);
      }

      .chapter {
        background: rgba($color: #feaf2f, $alpha: 0.05);
      }

      .sections {
        background: rgba($color: #6c4ecb, $alpha: 0.05);
      }

      &-item {
        height: calc(100% - 62px);
        box-sizing: border-box;
        overflow: hidden;
        overflow-y: auto;

        li {
          line-height: 42px;
          margin-bottom: 20px;
          font-size: 16px;
          color: #383838;

          &:last-child {
            margin-bottom: 0;
          }

          &.current,
          &:not(.current):not(.not-data):hover {
            color: #6c4ecb;
            cursor: pointer;
          }

          &.not-data {
            height: 100%;
            flex-direction: column;
            @include flexBox(center);

            img {
              width: 125px;
            }

            p {
              color: #b6b6b6;
              margin-top: 6px;
              font-size: 14px;
            }
          }
        }
      }
    }
  }

  &-time {
    box-sizing: border-box;
    padding: 36px 66px;

    .dialog-head {
      width: 100%;
      height: 32px;
      margin-bottom: 36px;
      color: #484848;
      @include flexBox;

      .month {
        margin-left: auto;
        margin-right: 28px;
      }

      .el-button {
        min-width: 32px;
        padding: 0;
        margin-left: 2px;
      }
    }

    .week-box {
      position: relative;
      width: 100%;
      height: 460px;

      .iconfont {
        height: 42px;
        line-height: 42px;
        padding: 0;
        font-size: 58px;
        position: absolute;
        top: 50%;
        transform: translateY(calc(-50% - 48px));

        &.week-prev {
          left: -92px;
        }

        &.week-next {
          right: -92px;
        }
      }

      .week {
        &-wrapper {
          width: 100%;
          height: 100%;
        }

        &-head {
          width: 100%;
          height: 102px;
          @include flexBox;

          li {
            width: 154px;
            height: 100%;
            box-sizing: border-box;
            background: #f6f7fc;
            border: solid #e0e3f0;
            border-width: 1px 1px 1px 0;
            flex-direction: column;
            @include flexBox(center);

            &:first-child {
              border-top-left-radius: 10px;
              border-left-width: 1px;
            }

            &:last-child {
              border-top-right-radius: 10px;
            }

            p {
              font-size: 16px;
              line-height: 30px;
              color: #282828;
            }
          }
        }

        &-body {
          width: calc(100% + 6px);
          height: calc(100% - 102px);
          overflow: hidden;
          overflow-y: auto;
        }

        &-row {
          height: 102px;
          @include flexBox;

          &:first-child {
            .week-col {
              border-top-width: 0;
            }
          }

          &:last-child {
            .week-col {
              border-bottom-width: 1px;

              &:first-child {
                border-bottom-left-radius: 10px;
              }

              &:last-child {
                border-bottom-right-radius: 10px;
              }
            }
          }
        }

        &-col {
          width: 154px;
          height: 100%;
          background: #fafbfe;
          box-sizing: border-box;
          border: solid #e0e3f0;
          border-width: 1px 1px 0 0;
          cursor: pointer;
          line-height: 20px;

          div {
            width: 100%;
            height: 100%;
            flex-direction: column;
            @include flexBox(center);
          }

          .subject {
            line-height: 30px;
            margin-bottom: 6px;
            font-size: 16px;
          }

          .grade {
            color: #3a3939;
          }

          .time {
            color: #676767;
          }

          &:first-child {
            border-left-width: 1px;
          }

          &.oneself {
            background: rgba($color: #6340c8, $alpha: 0.2);
            border-left: 4px solid #6c4ecb;
            padding-bottom: 16px;

            .subject {
              color: #6340c8;
            }

            &.over {
              cursor: not-allowed;
            }
          }

          &.completed {
            cursor: not-allowed;
            background: rgba($color: #2ac293, $alpha: 0.3);
            border-left: 4px solid #2ac293;

            .subject {
              color: #2ac293;
            }
          }

          &.employ {
            background: rgba($color: #e6b15f, $alpha: 0.05);
            border-left: 4px solid #ff9971;

            div {
              position: relative;
            }

            .del {
              width: 24px;
              height: 24px;
              line-height: 24px;
              font-size: 24px;
              position: absolute;
              top: 4px;
              right: 4px;
              color: #ff9971;
              transform: scale(1);

              &:hover {
                transform: scale(1.2);
              }
            }

            &.over {
              cursor: not-allowed;
            }
          }

          &.over {
            cursor: default;
          }

          &:not(.oneself) {
            *:not(p) {
              width: 100%;
              height: 100%;
            }

            .vacancy {
              flex-direction: column;
              opacity: 0;
              @include flexBox(center);

              * {
                pointer-events: none;
              }

              &.show {
                opacity: 1;
              }
            }
          }

          &:not(.oneself):not(.over):not(.completed).show,
          &:not(.oneself):not(.over):not(.completed):hover,
          &.employ {
            background: rgba($color: #e6b15f, $alpha: 0.05);
            border-left: 4px solid #ff9971;

            .subject {
              color: #ff9971;
            }

            .vacancy {
              opacity: 1;
            }
          }
        }
      }
    }
  }

  &-upload {
    box-sizing: border-box;
    padding-top: 40px;

    .el-form {
      box-sizing: border-box;
      padding: 0 20px 0 34px;

      ::v-deep {
        .form-upload .el-form-item__error {
          margin-top: -10px;
        }

        .el-upload {
          &--text {
            width: 150px;
            height: 100px;

            .el-upload-dragger .iconfont {
              margin-top: 24px;
              margin-bottom: 0;
            }
          }

          .tips {
            margin-top: -14px;
          }
        }
      }
    }

    .file-list {
      .file-item {
        height: 60px;
        margin-bottom: 10px;
        @include flexBox(space-between);

        &:last-child {
          margin-bottom: 0;
        }

        .iconfont-color {
          font-size: 52px;
          flex-shrink: 0;
          line-height: 60px;
          flex-shrink: 0;
        }

        .el-button {
          flex-shrink: 0;
          margin-bottom: 6px;
        }

        .name {
          color: #212122;
          flex-grow: 1;
          margin: 0 20px;
        }
      }
    }
  }

  &-knowledge {
    width: 432px;
    margin: 0 auto;
    box-sizing: border-box;
    padding: 40px 0;
    color: #6b6b6b;

    &--title,
    &--content {
      width: 100%;
      min-height: 46px;
      border-radius: 10px;
      background: #f0f0f0;
      box-sizing: border-box;
      padding: 10px 25px;
      line-height: 26px;
      margin-bottom: 24px;
    }

    &--content {
      min-height: 100px;
      padding: 18px 25px;
    }
  }

  &-knowledge-form {
    box-sizing: border-box;
    padding: 34px 20px 48px 64px;

    .el-form-item:last-child {
      margin-bottom: 0;
    }

    ::v-deep .el-textarea__inner {
      min-height: 110px !important;
    }
  }

  &-practise-form {
    box-sizing: border-box;
    padding: 28px 40px 40px;

    .form-wrapper {
      max-height: 500px;
      box-sizing: border-box;
      padding-top: 12px;
      padding-right: 46px;
      overflow: hidden;
      overflow-y: auto;

      ::v-deep {
        .el-upload--text {
          width: auto;
        }
      }

      .img-uploader {
        min-width: 46px;
        height: 46px;
        margin-left: 12px;
        // overflow: hidden;

        ::v-deep .el-upload--text {
          height: 46px;
        }

        .img-box {
          height: 46px;
          position: relative;

          img {
            height: 100%;
            border-radius: 10px;
          }

          .del {
            width: 24px;
            height: 24px;
            background: #fff;
            position: absolute;
            top: -12px;
            right: -8px;
            font-size: 32px;
            border-radius: 50%;
            color: #f8516d;
            @include flexBox(center);
          }
        }

        .upload-wrapper {
          width: 46px;
          height: 46px;
          background: #2ac293;
          border-radius: 10px;
          color: #fff;
          font-size: 30px;
          @include flexBox(center);
        }
      }

      .form-item {
        width: 100%;
        @include flexBox;
        align-items: flex-start;

        .el-button {
          flex-shrink: 0;
          margin-left: 10px;
        }

        .el-form-item {
          flex-grow: 1;
        }
      }

      .question-data {
        ::v-deep .el-checkbox,
        .el-radio {
          &__inner {
            width: 20px;
            height: 20px;
          }

          &__label {
            padding-left: 8px;
            color: #2b2b2b;
          }
        }
      }

      .question-options {
        margin-top: 5px;

        .option {
          width: 100%;
          @include flexBox;

          .el-form-item {
            margin-bottom: 0;
            flex-grow: 1;
          }

          &-list {
            width: 100%;
          }

          &-no {
            font-size: 14px;
            margin-right: 10px;
            flex-shrink: 0;
          }

          .el-input {
            flex-grow: 1;
          }

          .el-button {
            flex-shrink: 0;
            margin-left: 10px;
          }
        }

        .el-checkbox,
        .el-radio {
          height: 46px;
          line-height: 46px;
          margin: 12px 0 12px 24px;

          &-group {
            width: 100%;
            height: auto;
            display: block;
          }
        }
      }
    }
  }

  &-homework-form {
    box-sizing: border-box;
    padding: 46px 0 42px 0;

    .form-wrapper {
      width: calc(100% + 32px);
      box-sizing: border-box;
      box-sizing: border-box;
      padding: 0 146px 0 56px;
      overflow: hidden;
      overflow-y: auto;

      .el-select {
        margin-bottom: 12px;
      }

      .homework-questions {
        width: calc(100% + 202px);
        margin-left: -56px;
        box-sizing: border-box;
        padding: 40px 136px 0 0;
        margin-top: 30px;
        border-top: 1px solid rgba($color: #9e9fa2, $alpha: 0.18);

        .dialog-question-list {
          margin-top: 0;
          width: 100%;

          .question--data h5 .el-image {
            margin-right: 0;
          }

          .question-item {
            position: relative;
          }
        }

        .operate-group {
          width: 114px;
          position: absolute;
          top: -4px;
          right: -120px;
          @include flexBox;
        }
      }
    }

    .el-form-item:last-child {
      margin-bottom: 0;
    }

    ::v-deep .el-textarea__inner {
      min-height: 110px !important;
    }
  }

  &-question {
    box-sizing: border-box;
    padding: 40px 8px 40px 32px;

    &.detail,
    &-list {
      .dialog-question--head {
        width: 484px;
      }

      .question--data {
        margin-right: 40px;
      }
      .operate-group {
        width: 116px;
        flex-shrink: 0;
        margin-left: 10px;
        text-align: left;
      }

      .question,
      .subtopic {
        &-item {
          width: 100%;
          margin-bottom: 28px;
          @include flexBox;
          align-items: flex-start;

          &:last-child {
            margin-bottom: 0;
          }

          &.combination {
            .combination--title {
              font-size: 16px;
              color: #404040;
              line-height: 24px;
              margin: 18px 0;
            }
          }
        }

        &--num,
        &--type {
          flex-shrink: 0;
          line-height: 24px;
        }

        &--num {
          color: #1f1f1f;
          font-size: 16px;
        }

        &--type {
          height: 24px;
          background: #6340c8;
          border-radius: 12px 0px 12px 12px;
          text-align: center;
          color: #fff;
          margin: 0 8px 0 4px;
          font-size: 12px;
          padding: 0 8px;
        }

        &--data {
          flex-grow: 1;

          h5 {
            line-height: 24px;
            color: #1f1f1f;
            @include flexBox;
            align-items: baseline;

            span {
              text-align: justify;
              margin-right: 10px;
            }

            .el-image {
              flex-shrink: 0;
              margin-top: -7px;
            }
          }
        }

        &--options {
          margin-top: 12px;

          .option {
            line-height: 24px;
            color: #666666;
            @include flexBox;
            align-items: baseline;
            margin: 10px 0;

            .el-image {
              margin-left: 10px;
              flex-shrink: 0;
              margin-top: -4px;
            }
          }
        }

        &--knowledge,
        &--answer {
          width: 100%;
          min-height: 46px;
          border-radius: 10px;
          background: #f0f0f0;
          box-sizing: border-box;
          padding: 10px 25px;
          line-height: 26px;
          text-align: justify;
        }

        &--knowledge {
          margin-top: 18px;
        }

        &--answer {
          margin-top: 14px;
        }
      }

      .subtopic {
        &--num {
          font-size: 14px;
        }

        &--type {
          margin-right: 6px;
        }

        &--title {
          line-height: 24px;
          @include flexBox;
          align-items: baseline;

          span {
            text-align: justify;
            margin-right: 10px;
          }

          .el-image {
            flex-shrink: 0;
            margin-top: -7px;
          }
        }

        &--options,
        &--knowledge,
        &--answer {
          width: 100%;
        }
      }
    }

    &--head {
      width: 432px;
      margin-left: 18px;
      box-sizing: border-box;
    }

    &--title,
    &--desc {
      width: 100%;
      min-height: 46px;
      border-radius: 10px;
      background: #f0f0f0;
      box-sizing: border-box;
      padding: 10px 25px;
      line-height: 26px;
      margin-bottom: 10px;
    }

    &--desc {
      @include flexBox;
      align-items: flex-start;

      span {
        flex-shrink: 0;
      }
    }

    &-list {
      margin-top: 28px;
    }

    &--practise {
      max-height: 540px;
      margin-left: -20px;
      box-sizing: border-box;
      padding-top: 8px;
      overflow: hidden;
      overflow-y: auto;
      @include flexBox;
      align-items: flex-start;

      .question {
        &--type {
          flex-shrink: 0;
          line-height: 24px;
        }

        &--type {
          height: 24px;
          background: #6340c8;
          border-radius: 12px 0px 12px 12px;
          text-align: center;
          color: #fff;
          margin: 0 8px 0 4px;
          font-size: 12px;
          padding: 0 8px;
        }

        &--data {
          flex-grow: 1;

          h5 {
            line-height: 24px;
            color: #1f1f1f;
            @include flexBox;
            align-items: flex-start;

            span {
              text-align: justify;
              margin-right: 10px;
            }

            .el-image {
              flex-shrink: 0;
              margin-top: -7px;
            }
          }
        }

        &--options {
          margin-top: 12px;

          .option {
            line-height: 24px;
            color: #666666;
            @include flexBox;
            align-items: flex-start;
            margin: 10px 0;

            .el-image {
              margin-left: 10px;
              flex-shrink: 0;
              margin-top: -4px;
            }
          }
        }

        &--knowledge,
        &--answer {
          width: 100%;
          min-height: 46px;
          border-radius: 10px;
          background: #f0f0f0;
          box-sizing: border-box;
          padding: 10px 25px;
          line-height: 26px;
          text-align: justify;
        }

        &--knowledge {
          margin-top: 18px;
        }

        &--answer {
          margin-top: 14px;
        }
      }
    }
  }

  &-scroll {
    width: calc(100% + 20px);
    height: calc(100vh - 260px);
    box-sizing: border-box;
    padding-right: 20px;
    overflow: hidden;
    overflow-y: auto;
  }
}
::v-deep {
  .el-dialog.is-fullscreen {
    margin-left: 124px;
    width: calc(100% - 124px);
    height:80%;
  }
  .el-dialog__wrapper {
    &.calc {
      width: calc(100% - 600px);
      padding-left: 60px;

    }
  }
  .el-drawer__wrapper {
    z-index: 105 !important;
    .el-drawer__body {
      overflow: hidden;
      box-sizing: border-box;
      padding: 20px 0;
    }
  }
}

.courseware-view {
  width: 100%;
  height: calc(100vh - 122px);
  padding: 14px 0 !important;
  box-sizing: border-box;
  iframe {
    width: 100%;
    height: 100%;
  }
}
.large-dialog {
  max-width: 1280px;
  min-width: 1000px;
  width: 80%;
}
.create-question {
  box-sizing: border-box;
  padding: 28px 40px 40px;

  .create-form {
    &-wrapper {
      height: 500px;
      box-sizing: border-box;
      padding-top: 12px;
      padding-right: 46px;
      overflow: hidden;
      overflow-y: auto;

      ::v-deep {
        .el-upload--text {
          width: auto;
        }
      }

      .img-uploader {
        min-width: 46px;
        height: 46px;
        margin-left: 12px;

        ::v-deep .el-upload--text {
          height: 46px;
        }

        .img-box {
          height: 46px;
          position: relative;

          img {
            height: 100%;
            border-radius: 10px;
          }

          .del {
            width: 24px;
            height: 24px;
            background: #fff;
            position: absolute;
            top: -12px;
            right: -8px;
            font-size: 32px;
            border-radius: 50%;
            color: #f8516d;
            @include flexBox(center);
          }
        }

        .upload-wrapper {
          width: 46px;
          height: 46px;
          background: #2ac293;
          border-radius: 10px;
          color: #fff;
          font-size: 30px;
          @include flexBox(center);
        }
      }

      .form-item {
        width: 100%;
        @include flexBox;

        .el-button {
          flex-shrink: 0;
          margin-left: 10px;
        }

        .el-form-item {
          flex-grow: 1;
        }
      }

      .question-data {
        ::v-deep .el-checkbox,
        .el-radio {
          &__inner {
            width: 20px;
            height: 20px;
          }

          &__label {
            padding-left: 8px;
            color: #2b2b2b;
          }
        }
      }

      .compose-group .form-item {
        align-items: center;

        .el-form-item {
          margin-bottom: 0;
        }
      }

      .question-options {
        margin-top: 5px;

        .option {
          width: 100%;
          @include flexBox;

          .el-form-item {
            margin-bottom: 0;
            flex-grow: 1;
          }

          &-list {
            width: 100%;
          }

          &-no {
            font-size: 14px;
            margin-right: 10px;
            flex-shrink: 0;
          }

          .el-input {
            flex-grow: 1;
          }

          .el-button {
            flex-shrink: 0;
            margin-left: 10px;
          }
        }

        .el-checkbox,
        .el-radio {
          height: 46px;
          line-height: 46px;
          margin: 10px 0 4px 24px;

          &-group {
            width: 100%;
            height: auto;
            display: block;
          }
        }
      }

      .subtopic-data {
        box-sizing: border-box;
        padding-left: 82px;
      }
    }
  }
}
.import-dialog {
  box-sizing: border-box;
  padding: 40px 20px 28px;

  .download-formwork {
    text-align: right;
    margin-bottom: 18px;
  }

  .file-list {
    .file-item {
      height: 60px;
      margin-top: 20px;
      @include flexBox(space-between, flex-end);

      .iconfont-color {
        font-size: 52px;
        flex-shrink: 0;
        line-height: 60px;
        flex-shrink: 0;
      }

      .el-button {
        flex-shrink: 0;
        margin-bottom: 6px;
      }

      .name {
        color: #212122;
        flex-grow: 1;
        margin: 0 20px 10px;
      }
    }
  }
}

.mintitle {
  * {
    display: flex;
    align-items: center;
  }
  img {
    width: 40px;
    height: 40px;
  }
}
.practiseImport{
  margin-right: 21px;
}





.questionDoc{
  width: 100%;
  display: flex;
  height: 100%;
  box-sizing: border-box;
  padding-bottom: 90px;
  position: relative;
  .allquestion{
    width: 49%;
    background-color: #FFFFFF;
    border-radius: 10px;
    padding: 20px;
    height:  100%;
    overflow: auto;
    margin-right: 2%;
    box-sizing: border-box;
  }

  .checkedquestion{
    width: 49%;
    background-color: #FFFFFF;
    border-radius: 10px;
    padding: 20px;
    height: 100%;
    overflow: auto;
    box-sizing: border-box;
  }
  .questionDocOperation{
    position:absolute;
    left:0;
    width:100%;
    bottom: 0px;
    height: 70px;
    background-color: #FFFFFF;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  h3{
    font-size: 18px;
    line-height: 30px;
  }
  .hint{
    color: #666666;
    font-size: 14px;
    margin-bottom: 10px;
  }
  .list{
    width:100%;
    .item{
      border:1px solid #DDDDDD;
      width:100%;
      padding:0 15px 15px;
      border-radius: 10px;
      box-sizing: border-box;
      margin-bottom: 20px;
      ::v-deep img{
        max-width: 100% !important;
      }
      .headline{
        border-bottom:1px solid #DDDDDD;
        display: flex;
        align-items: center;
        height: 46px;
        .name{
          font-size: 16px;
          .bold{
            min-width: 56px;
            background: #6340c8;
            border-radius: 12px 0px 12px 12px;
            text-align: center;
            color: #fff;
            margin: 0 14px;
            box-sizing: border-box;
            padding: 0 9px;
            font-size: 12px;
            line-height: 20px;
            font-weight: 300;
            display: inline-block;
          }
        }
        .delete{
          margin-left: auto;
          width: 27px;
          line-height: 27px;
          background-color: #FF0000;
          color: #FFFFFF;
          border-radius: 50%;
          text-align: center;
          font-size: 14px;
          opacity: 0.5;
          &:hover{
            cursor: pointer;
            opacity:1;
          }
        }
      }
      .realm{
        display: flex;
        align-items: baseline;
        padding:5px 0;
        margin-top: 20px;
        position: relative;
        margin-bottom: 5px;
        .xing{
          color:#FF0000;
          position: absolute;
          left: -10px;
          top: 4px;
        }
        .title{
          color:#cccccc;
          font-size: 12px;
          margin-left: 5px;
        }
        .addoptin{
          margin-left: auto;
          color:#6340c8;
          font-size: 14px;
          i{
            margin-right: 5px;
          }
          &:hover{
            cursor: pointer;
          }
        }
      }
      .topic{
        width: 100%;
        display: flex;
        align-items: center;
      }
      .tinymce{
        flex: 1;
        min-height: 46px;
        line-height: 30px;
        border-radius: 10px;
        font-size: 14px;
        color: #191919;
        box-sizing: border-box;
      }
      .tinymceval{
        background-color: #F6F7FA;
        flex: 1;
        line-height: 22px;
        border-radius: 5px;
        padding: 5px 20px;
      }
      .answers{
        width:100%;
        li{
          width:100%;
          display: flex;
          align-items: flex-start;
          margin-bottom: 10px;
          line-height: 32px;
          .el-checkbox{
            padding-right: 15px;
            margin-top: 5px;
          }
          .el-radio{
            padding-right: 15px;
            margin-top: 5px;
          }
          ::v-deep .el-radio__label{
            padding-left:0;
          }
          .title{
            color:#666666;
            font-size:16px;
            margin-right: 5px;
          }
          .index{
            margin-right: 5px;
          }
          i{
            margin-left: 10px;
            width: 27px;
            line-height: 27px;
            background-color: #FF0000;
            color: #FFFFFF;
            border-radius: 50%;
            text-align: center;
            font-size: 14px;
            opacity: 0.5;
            &:hover{
              cursor: pointer;
              opacity:1;
            }
          }
        }
      }
    }
  }
}
::v-deep .el-upload {
  &--text {
    width: 100%;
    height: 150px;
    .el-upload-dragger {
      width: 100%;
      height: 100%;
      border-radius: 10px;
      background: #f6f7fa;
      border-style: solid;
      border-color: transparent;
      .iconfont {
        font-size: 30px;
        color: #b6b6b6;
        margin-top: 50px;
        margin-bottom: 24px;
      }
      &:hover {
        .iconfont {
          color: #6c4ecb;
        }
      }
    }
  }
}


</style>

<template>
  <section :class="showQuestionDoc?'width100':''" class="prepare">
    <template v-if="!showQuestionDoc">
      <div class="page-inner" v-loading="allloading">
        <template v-if="!$isEmpty(searchData) && !searchDialog">
          <ul class="plate-group">
            <!-- 课件 -->
            <li class="plate courseware">
              <h4 class="box-head">
                <span class="bold">课件</span>
              </h4>
              <div class="courseware-inner">
                <div
                    class="courseware--info"
                    :class="{ 'no-data': !courseware.length }"
                >
                  <template v-if="courseware.length">
                    <div class="info-data">
                      <p class="file-title">
                        <i class="iconfont">&#xe62f;</i>
                        <span
                        >{{ courseware[0].tetea_cou_title }}.{{
                            courseware[0].tetea_cou_type
                          }}</span
                        >
                      </p>
                      <p class="upload-time">
                        时间：{{
                          courseware[0].create_time | formatTime("YYYY年M月D日")
                        }}
                      </p>
                    </div>
                    <el-button
                        type="custom_danger"
                        size="mini"
                        plain
                        @click="deleteCourseware"
                    >删除</el-button
                    >
                  </template>
                  <div class="no-data--empty" v-show="!courseware.length">
                    <img src="@assets/images/no-data3.png" alt />
                    <p>暂无上传课件信息哦~</p>
                  </div>
                </div>
                <div class="foot-operation">
                  <!-- <el-button
                    type="custom_primary"
                    size="small"
                    @click="$router.push({ name: 'TEACHING_COURSEWARE' })"
                    >查找课件资源</el-button
                  > -->
                  <el-button
                      type="custom_warning"
                      size="small"
                      @click="showDialog('upload')"
                  >上传课件</el-button
                  >
                </div>
              </div>
            </li>
            <!-- 知识点 -->
            <li class="plate knowledge">
              <h4 class="box-head">
                <span class="bold">知识点</span>
                <div class="knowledge_button practiseImport">
                  <el-button
                      type="text"
                      class="primary"
                      @click="showDialog('knowledgeImport')"
                  >批量导入</el-button
                  >
                  <el-button
                      type="text"
                      class="primary"
                      @click="showDialog('knowledge')"
                  >新建 +</el-button
                  >
                </div>
              </h4>
              <div
                  class="inner-box"
                  :class="{ 'no-data': !prepareKnowledge.length }"
              >
                <ul class="list-wrapper" v-if="prepareKnowledge.length">
                  <li
                      v-for="(item, index) in prepareKnowledge"
                      :key="item.tetea_kno_id"
                  >
                    <p class="line-text--1st">
                      {{ index + 1 }}. {{ item.tetea_kno_title }}
                    </p>
                    <div class="btns">
                      <el-button
                          type="custom_danger"
                          size="mini"
                          plain
                          @click="delKnowledge(item.tetea_kno_id)"
                      >删除</el-button
                      >
                      <el-button
                          type="custom_primary"
                          size="mini"
                          plain
                          @click="showDialog('knowledge', item)"
                      >编辑</el-button
                      >
                      <el-button
                          type="custom_success"
                          size="mini"
                          plain
                          @click="showDialog('knowledgeDetail', item)"
                      >查看</el-button
                      >
                    </div>
                  </li>
                </ul>
                <div class="no-data--empty" v-show="!prepareKnowledge.length">
                  <img src="@assets/images/no-data3.png" alt />
                  <p>暂无相关知识点哦~</p>
                </div>
              </div>
            </li>
            <!-- 课堂练习 -->
            <li class="plate practise">
              <h4 class="box-head">
                <span class="bold">课堂练习</span>
                <div class="practiseImport">
                  <el-button
                    type="text"
                    class="primary"
                    @click="showCutting=true"
                    >图片识别</el-button
                  >
                  <el-button
                      type="text"
                      class="primary"
                      @click="showDialog('practiseImport')"
                  >批量导入</el-button
                  >
                  <el-button
                      type="text"
                      class="primary"
                      @click="showDialog('practise')"
                  >新建 +</el-button
                  >
                </div>
              </h4>
              <div
                  class="inner-box"
                  :class="{ 'no-data': !preparePractise.length }"
              >
                <ul class="list-wrapper">
                  <li
                      v-for="(item, index) in preparePractise"
                      :key="item.tetea_pra_id"
                  >
                    <div class="title">
                      {{ index + 1 }}.
                      <el-image
                          style="width: 46px; height: 32px; margin: 0 5px"
                          v-if="item.tetea_pra_title_image"
                          fit="cover"
                          :src="formatfile(item.tetea_pra_title_image)"
                          :preview-src-list="[
                          formatfile(item.tetea_pra_title_image),
                        ]"
                      />
                      <p class="line-text--1st">
                        <span
                            class="mintitle richflex center"
                            style="height: 60px;line-height: 60px;"
                            v-html="item.tetea_pra_title"
                        ></span>
                      </p>
                    </div>
                    <div class="btns">
                      <el-button
                          type="custom_danger"
                          size="mini"
                          plain
                          @click="delPractise(item.tetea_pra_id)"
                      >删除</el-button
                      >
                      <el-button
                          type="custom_primary"
                          size="mini"
                          plain
                          @click="showDialog('practise', item.tetea_pra_id)"
                      >编辑</el-button
                      >
                      <el-button
                          type="custom_success"
                          size="mini"
                          plain
                          @click="showDialog('practiseDetail', item.tetea_pra_id)"
                      >查看</el-button
                      >
                    </div>
                  </li>
                </ul>
                <div class="no-data--empty" v-show="!preparePractise.length">
                  <img src="@assets/images/no-data3.png" alt />
                  <p>暂无相关课堂练习哦~</p>
                </div>
              </div>
            </li>
            <!-- 作业 -->
            <li class="plate homework">
              <h4 class="box-head">
                <span class="bold">作业</span>
                <div class="practiseImport">
                  <el-button
                      v-if="
                    $isEmpty(prepareHomework) || $isEmpty(prepareSchoolHomework)
                  "
                      type="text"
                      class="primary"
                      @click="showDialog('homework')"
                  >新建 +</el-button
                  >
                </div>
              </h4>
              <div
                  class="inner-box"
                  :class="{
                  'no-data':
                    $isEmpty(prepareHomework) && $isEmpty(prepareSchoolHomework),
                }"
              >
                <template v-if="!$isEmpty(prepareHomework)">
                  <el-tag type="info">家庭作业</el-tag>
                  <div class="homework-title">
                    <p>{{ prepareHomework.tetea_hom_title }}</p>

                    <div class="btns">
                      <el-button
                          type="custom_danger"
                          size="mini"
                          plain
                          @click="delHomework(prepareHomework.tetea_hom_id)"
                      >删除</el-button
                      >
                      <el-button
                          type="custom_primary"
                          size="mini"
                          plain
                          @click="
                          showDialog('homework', prepareHomework.tetea_hom_id)
                        "
                      >编辑</el-button
                      >
                      <el-button
                          type="custom_success"
                          size="mini"
                          plain
                          @click="
                          showDialog(
                            'homeworkDetail',
                            prepareHomework.tetea_hom_id
                          )
                        "
                      >查看</el-button
                      >
                    </div>
                  </div>
                  <!--<div
                    class="homework-desc"

                    v-html="
                      prepareHomework.tetea_hom_remark
                        .replace(/\n|\r\n/g, '<br>')
                        .replace(/ /g, '&nbsp;')
                    "
                  ></div>
                  -->
                </template>

                <template v-if="!$isEmpty(prepareSchoolHomework)">
                  <el-tag type="info">校内作业</el-tag>
                  <div class="homework-title">
                    <p>{{ prepareSchoolHomework.tetea_hom_title }}</p>
                    <div class="btns">
                      <el-button
                          type="custom_danger"
                          size="mini"
                          plain
                          @click="delHomework(prepareSchoolHomework.tetea_hom_id)"
                      >删除</el-button
                      >
                      <el-button
                          type="custom_primary"
                          size="mini"
                          plain
                          @click="
                          showDialog(
                            'homework',
                            prepareSchoolHomework.tetea_hom_id
                          )
                        "
                      >编辑</el-button
                      >
                      <el-button
                          type="custom_success"
                          size="mini"
                          plain
                          @click="
                          showDialog(
                            'homeworkDetail',
                            prepareSchoolHomework.tetea_hom_id
                          )
                        "
                      >查看</el-button
                      >
                    </div>
                  </div>
                  <!--<div
                    class="homework-desc"

                    v-html="
                      prepareSchoolHomework.tetea_hom_remark
                        .replace(/\n|\r\n/g, '<br>')
                        .replace(/ /g, '&nbsp;')
                    "
                  ></div>
                  -->
                </template>
                <div
                    class="no-data--empty"
                    v-show="
                    $isEmpty(prepareHomework) && $isEmpty(prepareSchoolHomework)
                  "
                >
                  <img src="@assets/images/no-data3.png" alt />
                  <p>暂无相关作业哦~</p>
                </div>
              </div>
            </li>

          </ul>
        </template>
        <div class="no-search" v-if="$isEmpty(searchData) && !searchDialog"  v-loading="true">
          <img src="@assets/images/no-data3.png" alt="" />
          <el-button
              type="custom_primary"
              size="small"
              @click="showDialog('search')"
          >选择备课章节</el-button
          >
        </div>
      </div>
    </template>
    <!-- 导入的试题数据 -->
    <div class="questionDoc" v-if="showQuestionDoc">
      <!-- 所有试题 -->
      <div class="allquestion">
        <h3>批量导入习题</h3>
        <div class="hint">成功识别 <span>{{succNum}}</span> 题</div>
        <div class="list">

          <div class="item" v-for="(item, index) in questionDoc" :key="index">
            <div class="headline">
              <div class="name">第{{ index + 1 }}题<span class="bold">{{ item.syque_typ_name }}</span></div>
              <div class="delete"><i class="el-icon-delete" @click="deleteQuestionDocItem(index)"></i></div>
            </div>
            <div class="realm">
              <span class="xing">*</span>
              题目
            </div>
            <div class="topic">
              <try-editorquestions class="tinymce" v-model="item.tetea_pra_title"></try-editorquestions>
            </div>
            <template v-if="item.syque_typ_id == 2">
              <!-- 多选题目 -->
              <div class="realm">
                <span class="xing">*</span>
                选项
                <span class="title">勾选正确答案，多选题请勾选多个选项</span>
                <div class="addoptin" @click="addDocQuestion(item, index)">
                  <i class="el-icon-circle-plus-outline"></i>添加选项
                </div>
              </div>
              <ul class="answers">
                <li v-for="(item2, index2) in item.question_item" :key="index2">
                  <el-checkbox v-model="item2.tetea_pra_ite_isanswer"  :true-label="10" :false-label="20"></el-checkbox>
                  <div class="index">{{ item2.tetea_pra_ite_code }}.</div>
                  <try-editorquestions class="tinymce" v-model="item2.tetea_pra_ite_title"></try-editorquestions>
                  <i class="el-icon-delete" @click="deleteDocQuestionItem(item, index2)"></i>
                </li>
              </ul>
            </template>
            <template v-if="item.syque_typ_id == 1 || item.syque_typ_id == 5">
              <!-- 单选题 -->
              <div class="realm">
                <span class="xing">*</span>
                选项
                <span class="title">勾选正确答案</span>
                <div class="addoptin" @click="addDocQuestion(item, index)">
                  <i class="el-icon-circle-plus-outline"></i>添加选项
                </div>
              </div>
              <ul class="answers">
                <li v-for="(item2, index2) in item.question_item" :key="index2">
                  <el-radio v-model="item.answer" :label="item2.tetea_pra_ite_code">
                    <span></span>
                  </el-radio>
                  <div class="index">{{ item2.tetea_pra_ite_code }}.</div>
                  <try-editorquestions class="tinymce" v-model="item2.tetea_pra_ite_title"></try-editorquestions>
                  <i class="el-icon-delete" @click="deleteDocQuestionItem(item, index2)"></i>
                </li>
              </ul>
            </template>
            <template v-if="item.syque_typ_id == 3 || item.syque_typ_id == 4 || item.syque_typ_id == 7 || item.syque_typ_id == 8">
              <!-- 填空题 -->
              <div class="realm">
                <span class="xing">*</span>
                答案
                <div class="addoptin" @click="addDocQuestion(item, index)">
                  <i class="el-icon-circle-plus-outline"></i>添加答案
                </div>
              </div>
              <ul class="answers">
                <li v-for="(item2, index2) in item.question_item" :key="index2">
                  <div class="title">第{{ index2 + 1 }}空</div>
                  <try-editorquestions class="tinymce" v-model="item2.tetea_pra_ite_title"></try-editorquestions>
                  <i class="el-icon-delete" @click="deleteDocQuestionItem(item, index2)"></i>
                </li>
              </ul>
            </template>
            <div class="realm">
              解析
            </div>
            <div class="topic">
              <try-editorquestions class="tinymce" v-model="item.tetea_pra_analysis"></try-editorquestions>
            </div>
            <div class="realm">
              困难度
            </div>
            <div class="difficulty">
              <el-radio v-model="item.tetea_pra_difficulty" :label="10">简单</el-radio>
              <el-radio v-model="item.tetea_pra_difficulty" :label="20">普通</el-radio>
              <el-radio v-model="item.tetea_pra_difficulty" :label="30">困难</el-radio>
            </div>
            <div class="realm">
              绑定知识点
            </div>
            <el-select class="select" v-model="item.tetea_kno_id" placeholder="请选择">
              <el-option
                  v-for="item2 in prepareKnowledge"
                  :key="item2.tetea_kno_id"
                  :label="item2.tetea_kno_title"
                  :value="item2.tetea_kno_id"
              />
            </el-select>
          </div>
        </div>
      </div>
      <!-- 选中的试题 -->
      <div class="checkedquestion">
        <div class="list">
          <div class="item" v-for="(item,index) in questionDoc" :key="index">
            <div class="headline">
              <div class="name">第{{index+1}}题<span class="bold">{{item.syque_typ_name}}</span></div>
            </div>
            <div class="realm">
              题目
            </div>
            <div class="topic">
              <div class="tinymceval" v-html="item.tetea_pra_title"></div>
            </div>
            <template v-if="item.syque_typ_id==2">
              <!-- 多选题目 -->
              <div class="realm">
                选项
              </div>
              <ul class="answers">
                <li v-for="(item2,index2) in item.question_item" :key="index2">
                  <el-checkbox v-model="item2.tetea_pra_ite_isanswer"  :true-label="10" :false-label="20"></el-checkbox>
                  <div class="index">{{item2.tetea_pra_ite_code}}.</div>
                  <div class="tinymceval" v-html="item2.tetea_pra_ite_title"></div>
                  <i class="el-icon-delete"></i>
                </li>
              </ul>
            </template>
            <template v-if="item.syque_typ_id==1||item.syque_typ_id==5">
              <!-- 单选题 -->
              <div class="realm">
                选项
              </div>
              <ul class="answers">
                <li v-for="(item2,index2) in item.question_item" :key="index2">
                  <el-radio v-model="item.answer" :label="item2.tetea_pra_ite_code">
                    <span></span>
                  </el-radio>
                  <div class="index">{{item2.tetea_pra_ite_code}}.</div>
                  <div class="tinymceval" v-html="item2.tetea_pra_ite_title"></div>
                </li>
              </ul>
            </template>
            <template v-if="item.syque_typ_id==3||item.syque_typ_id==4||item.syque_typ_id==7||item.syque_typ_id==8">
              <!-- 填空题 -->
              <div class="realm">
                答案
              </div>
              <ul class="answers">
                <li v-for="(item2,index2) in item.question_item" :key="index2">
                  <div class="title">第{{index2+1}}空</div>
                  <div class="tinymceval" v-html="item2.tetea_pra_ite_title"></div>
                </li>
              </ul>
            </template>
            <div class="realm">
              解析
            </div>
            <div class="topic">
              <div class="tinymceval" v-html="item.tetea_pra_analysis"></div>
            </div>
            <div class="realm">
              困难度
            </div>
            <div class="difficulty">
              <el-radio v-model="item.tetea_pra_difficulty" v-if="item.tetea_pra_difficulty==10" :label="10">简单</el-radio>
              <el-radio v-model="item.tetea_pra_difficulty" v-if="item.tetea_pra_difficulty==20" :label="20">普通</el-radio>
              <el-radio v-model="item.tetea_pra_difficulty" v-if="item.tetea_pra_difficulty==30" :label="30">困难</el-radio>
            </div>
            <div class="realm">
              绑定知识点
            </div>
            <el-select class="select" v-model="item.tetea_kno_id" disabled placeholder="请选择">
              <el-option
                  v-for="item2 in prepareKnowledge"
                  :key="item2.tetea_kno_id"
                  :label="item2.tetea_kno_title"
                  :value="item2.tetea_kno_id"
              />
            </el-select>
          </div>
        </div>
      </div>
      <div class="questionDocOperation">
        <el-button type="custom_primary" size="small" v-loading="submitQuestionDocload" @click="questionDocsubmit()">确 定</el-button>
        <el-button type="custom_warning" size="small" @click="showQuestionDoc=false">取 消</el-button>
      </div>
    </div>
    <!-- 数据筛选弹窗 -->
    <el-dialog
        title="筛选内容"
        :visible.sync="searchDialog"
        custom-class="large-dialog"
        :show-close="false"
        :close-on-click-modal="false"
    >
      <div class="dialog-search">
        <div class="curriculum">
          <div class="edition">
            <h5 class="bold">年级</h5>
            <ul class="curriculum-item">
              <li
                  :class="{
                  current:
                    searchData.grade && searchData.grade.id == item.grade_id,
                }"
                  v-for="item in gradeList"
                  :key="item.grade_id"
                  @click="
                  chooseSearchKey('grade', item.grade_id, item.grade_name)
                "
              >
                {{ item.grade_name }}
              </li>
            </ul>
          </div>
          <div class="edition-no">
            <h5 class="bold">科目</h5>
            <ul class="curriculum-item">
              <li
                  :class="{
                  current:
                    searchData.sysub && searchData.sysub.id == item.sysub_id,
                }"
                  v-for="item in subjectList"
                  :key="item.sysub_id"
                  @click="
                  chooseSearchKey('sysub', item.sysub_id, item.sysub_name)
                "
              >
                {{ item.sysub_name }}
              </li>
            </ul>
          </div>
          <div class="chapter">
            <h5>
              <span class="bold">章</span>
              <el-input
                  v-model.trim="chapterKey"
                  maxlength="100"
                  placeholder="请输入关键字搜索"
                  clearable
                  @change="getChapters(1)"
                  @clear="getChapters(1)"
              >
                <span slot="suffix" class="iconfont">&#xe61c;</span>
              </el-input>
            </h5>
            <ul class="curriculum-item">
              <template v-if="chapterData && chapterData.length">
                <li
                    :class="{
                    current:
                      searchData.chaper &&
                      searchData.chaper.id == item.sccou_cha_id,
                  }"
                    v-for="item in chapterData"
                    :key="item.sysub_id"
                    @click="
                    chooseSearchKey(
                      'chaper',
                      item.sccou_cha_id,
                      item.sccou_cha_title
                    )
                  "
                >
                  {{ item.sccou_cha_title }}
                </li>
              </template>
              <li class="not-data" v-else>
                <img src="@assets/images/no-data2.png" alt />
                <p>暂无选项哦~</p>
              </li>
            </ul>
          </div>
          <div class="sections">
            <h5>
              <span class="bold">节</span>
              <el-input
                  v-model.trim="jointKey"
                  maxlength="100"
                  placeholder="请输入关键字搜索"
                  clearable
                  @change="getChapters(2)"
                  @clear="getChapters(2)"
              >
                <span slot="suffix" class="iconfont">&#xe61c;</span>
              </el-input>
            </h5>
            <ul class="curriculum-item">
              <template v-if="jointData && jointData.length">
                <li
                    :class="{
                    current:
                      searchData.joint &&
                      searchData.joint.id == item.sccou_cha_id,
                  }"
                    v-for="item in jointData"
                    :key="item.sysub_id"
                    @click="
                    chooseSearchKey(
                      'joint',
                      item.sccou_cha_id,
                      item.sccou_cha_title
                    )
                  "
                >
                  {{ item.sccou_cha_title }}
                </li>
              </template>
              <li class="not-data" v-else>
                <img src="@assets/images/no-data2.png" alt />
                <p>暂无选项哦~</p>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div slot="footer" class="foot-center">
        <el-button
            type="custom_info"
            size="small"
            @click="hideDialog('search')"
            v-if="teteaId"
        >取 消</el-button
        >
        <el-button type="custom_primary" size="small" @click="getPrepareId"
        >确 定</el-button
        >
      </div>
    </el-dialog>
    <!-- 选择上课时间 -->
    <el-dialog
        title="选择上课时间"
        :visible.sync="timeDialog"
        custom-class="large-dialog"
        :show-close="false"
    >
      <div class="dialog-time">
        <h3 class="dialog-head">
          <span class="bold">课程表</span>
          <span class="bold month"
          >{{ currentYear }}年{{ currentMonth }}月</span
          >
          <span>
            <el-button
                class="iconfont"
                type="custom_primary"
                size="mini"
                @click="handleChangeMonth(0)"
            >&#xe612;</el-button
            >
            <el-button
                class="iconfont"
                type="custom_primary"
                size="mini"
                @click="handleChangeMonth(1)"
            >&#xe613;</el-button
            >
          </span>
        </h3>
        <div class="week-box">
          <el-button
              class="iconfont week-prev"
              type="text"
              @click="handleChangeWeek(0)"
          >&#xe656;</el-button
          >
          <el-button
              class="iconfont week-next"
              type="text"
              @click="handleChangeWeek(1)"
          >&#xe8ab;</el-button
          >
          <div class="week-wrapper">
            <ul class="week-head">
              <li v-for="(day, index) in weekDays" :key="index">
                <p>{{ day | formatTime("M月D日") }}</p>
                <p>周{{ week[index] }}</p>
              </li>
            </ul>
            <div class="week-body">
              <ul
                  class="week-row"
                  v-for="(row, r_index) in teacherLessons"
                  :key="r_index"
              >
                <li
                    class="week-col"
                    :class="
                    weekTrendsClass(row, col, `popover${r_index}_${c_index}`)
                  "
                    v-for="(col, c_index) in row.classedule"
                    :key="c_index"
                >
                  <!-- 内容填充课程单元格 -->
                  <div
                      v-if="!$isEmpty(col.data)"
                      @click="
                      chooseTeachTime(
                        col,
                        row.time_text,
                        weekTrendsClass(
                          row,
                          col,
                          `popover${r_index}_${c_index}`
                        ).indexOf('completed')
                      )
                    "
                  >
                    <i
                        class="del iconfont"
                        v-if="col.data.is_type == 2 && col.state == 2"
                        @click.stop="clearTeachTime(col.data.tecla_id)"
                    >&#xe640;</i
                    >
                    <p class="subject bold">{{ col.data.sysub_name }}</p>
                    <p class="grade">{{ col.data.grade_class }}</p>
                    <p class="time">{{ row.time_text }}</p>
                  </div>
                  <!-- 空白课程单元格 -->
                  <div
                      v-if="$isEmpty(col.data)"
                      @click="showPopover(`popover${r_index}_${c_index}`)"
                  >
                    <el-popover
                        v-if="
                        weekTrendsClass(
                          row,
                          col,
                          `popover${r_index}_${c_index}`
                        ).indexOf('over') == -1
                      "
                        :ref="`popover${r_index}_${c_index}`"
                        :placement="(c_index < 5 && 'right') || 'left'"
                        width="256"
                        trigger="click"
                    >
                      <div class="popover-contianer">
                        <el-form ref="lessonForm" :model="lessonForm">
                          <el-form-item>
                            <el-select
                                v-model="lessonForm.grade_class"
                                placeholder="请选择备课班级"
                                filterable
                                clearable
                            >
                              <el-option
                                  v-for="item in classList"
                                  :key="item.value"
                                  :label="`${item.sccla_grade_name}${item.sccla_name}`"
                                  :value="`${item.sccla_grade},${item.sccla_id}`"
                              />
                            </el-select>
                          </el-form-item>
                          <el-form-item>
                            <el-button
                                type="custom_info"
                                size="small"
                                @click="
                                changePopover = false;
                                hidePopover();
                              "
                            >
                              取消
                            </el-button>
                            <el-button
                                type="custom_primary"
                                size="small"
                                :disabled="!lessonForm.grade_class || popoverLoad"
                                @click="setTeachTime(row, weekDays[c_index])"
                                v-loading="popoverLoad"
                            >
                              确定
                            </el-button>
                          </el-form-item>
                        </el-form>
                      </div>
                      <div
                          class="vacancy"
                          :class="{
                          show:
                            currentPopover == `popover${r_index}_${c_index}`,
                        }"
                          slot="reference"
                      >
                        <p class="subject">去占课</p>
                        <p class="time">{{ row.time_text }}</p>
                      </div>
                    </el-popover>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </el-dialog>
    <!-- 上传课件 -->
    <el-dialog
        title="上传课件"
        :visible.sync="uploadDialog"
        width="700px"
        :show-close="false"
        @close="hideDialog('upload')"
    >
      <div class="dialog-upload">
        <el-form
            ref="uploadForm"
            :model="uploadForm"
            label-width="80px"
            :rules="uploadRules"
        >
          <el-form-item label="课件标题" prop="tetea_cou_title">
            <el-input
                v-model.trim="uploadForm.tetea_cou_title"
                maxlength="100"
                placeholder="请输入"
            />
          </el-form-item>
          <el-form-item label="课件描述" prop="tetea_cou_remark">
            <el-input
                v-model.trim="uploadForm.tetea_cou_remark"
                type="textarea"
                maxlength="200"
                placeholder="请输入"
            />
          </el-form-item>
          <el-form-item class="form-upload" label="课件" prop="tetea_cou_url">
            <!-- :fileSize="upFileOption.fileSize" -->
            <UploadCom v-model="uploadForm.tetea_cou_url" fileSize="200" :fileLimit="1"
                       listType="text" :fileType="upFileOption.fileType" />

          </el-form-item>
        </el-form>
      </div>
      <div slot="footer" class="foot-center">
        <el-button type="custom_info" size="small" @click="hideDialog('upload')"
        >取 消</el-button
        >
        <el-button
            type="custom_primary"
            size="small"
            @click="addCourseware"
            :disabled="coursewareLoad"
            v-loading="coursewareLoad"
        >确 定</el-button
        >
      </div>
    </el-dialog>
    <!-- 知识点导入模板 -->
    <el-dialog
        title="导入"
        :visible.sync="knowledgeImportDialog"
        width="560px"
        :show-close="false"
        @close="[hideDialog('knowledgeImport'),$refs.importUpload.clearFiles()]"
    >
      <div class="import-dialog">
        <p class="download-formwork">
          <el-button
              type="custom_primary"
              size="small"
              @click="downloadTemplate"
          >下载模板</el-button
          >
        </p>
        <el-upload
            class="upload-demo"
            ref="importUpload"
            drag
            :action="$upload.fileAction2"
            multiple
            :limit="1"
            accept=".xls,.xlsx"
            :on-success="uploadknowledgeImportSuccess"
            :on-error="handleUploadError"
        >
          <p class="iconfont">&#xe603;</p>
          <p class="tips">拖拽或<span>点击上传</span></p>
          <div class="el-upload__tip" slot="tip">
            只能上传xls/xlsx文件,每次只能导入一个文件
          </div>
        </el-upload>
      </div>
      <div slot="footer">
        <el-button
            type="custom_info"
            size="small"
            @click="[hideDialog('knowledgeImport'),$refs.importUpload.clearFiles()]"
        >
          取 消
        </el-button>
        <el-button
            type="custom_primary"
            size="small"
            @click="uploadTimetable"
            :disabled="btnload"
            v-loading="btnload"
        >
          确 定
        </el-button>
      </div>
    </el-dialog>
    <!-- 练习题导入模板 -->
    <el-dialog
        title="导入"
        :visible.sync="practiseImportDialog"
        width="560px"
        :show-close="false"
        @close="[hideDialog('practiseImport'),$refs.importUpload2.clearFiles()]"
    >
      <div class="import-dialog">
        <p class="download-formwork">
          <el-button type="custom_primary" size="small" @click="downloadPractiseTemplate(1)" :disabled="download1" v-loading="download1">下载excel模板</el-button>
          <el-button type="custom_primary" size="small" @click="downloadPractiseTemplate(2)" :disabled="download2" v-loading="download2">下载word模板</el-button>
        </p>
        <el-upload
            class="upload-demo"
            ref="importUpload2"
            drag
            :action="$upload.fileAction2"
            multiple
            :limit="1"
            accept=".doc,.docx,.xls,.xlsx,.xlsm"
            :before-upload="(file) => {return $beforFileUpload(file, ['doc', 'docx', 'xls', 'xlsx', 'xlsm']);}"
            :on-success="uploadpractiseImportSuccess"
            :on-error="handleUploadError"
        >
          <p class="iconfont">&#xe603;</p>
          <p class="tips">拖拽或<span>点击上传</span></p>
          <div class="el-upload__tip" slot="tip">
            每次只能导入一个文件
          </div>
        </el-upload>

      </div>
      <div slot="footer">
        <el-button
            type="custom_info"
            size="small"
            @click="[hideDialog('practiseImport'),$refs.importUpload2.clearFiles()]"
        >
          取 消
        </el-button>
        <el-button
            type="custom_primary"
            size="small"
            @click="uploadPractisetable"
            :disabled="btnload"
            v-loading="btnload"
        >
          确 定
        </el-button>
      </div>
    </el-dialog>
    <!-- 知识点 新建||编辑 -->
    <el-dialog
        :title="(knowledgeForm.tetea_kno_id && '编辑知识点') || '新建知识点'"
        :visible.sync="knowledgeDialog"
        width="900px"
        :show-close="false"
        @close="hideDialog('knowledge')"
    >
      <div class="dialog-knowledge-form">
        <el-form
            label-width="80px"
            ref="knowledgeForm"
            :model="knowledgeForm"
            :rules="knowledgeRules"
        >
          <el-form-item label="标题" prop="tetea_kno_title">
            <el-input
                v-model.trim="knowledgeForm.tetea_kno_title"
                maxlength="100"
                placeholder="请输入"
            />
          </el-form-item>
          <el-form-item label="内容" prop="tetea_kno_content">
            <el-input
                v-model.trim="knowledgeForm.tetea_kno_content"
                type="textarea"
                maxlength="200"
                placeholder="请输入内容"
            />
          </el-form-item>
        </el-form>
      </div>
      <div slot="footer">
        <el-button
            type="custom_info"
            size="small"
            @click="hideDialog('knowledge')"
        >取 消</el-button
        >
        <el-button
            type="custom_primary"
            size="small"
            @click="addKnowledge"
            :disabled="knowledgeLoad"
            v-loading="knowledgeLoad"
        >
          确 定
        </el-button>
      </div>
    </el-dialog>
    <!-- 知识点详情 -->
    <el-dialog
        :visible.sync="knowledgeDetailDialog"
        :class="{ calc: previewPrepare }"
        width="554px"
        @close="hideDialog('knowledgeDetail')"
        title="知识点详情"
    >
      <div class="dialog-knowledge">
        <p class="dialog-knowledge--title">
          {{ knowledgeDetail.tetea_kno_title }}
        </p>
        <p class="dialog-knowledge--content">
          {{ knowledgeDetail.tetea_kno_content }}
        </p>
      </div>
    </el-dialog>
    <!-- 课堂练习 新建||编辑 -->
    <el-dialog
        :title="(practiseForm.tetea_pra_id && '编辑') || '新建'"
        :visible.sync="practiseDialog"
        width="900px"
        @close="hideDialog('practise')"
        :show-close="false"
    >
      <div class="create-question">
        <div class="create-form-wrapper">
          <el-form
              ref="practiseForm"
              :model="practiseForm"
              label-width="82px"
              :rules="practiseRules"
          >
            <el-form-item label="题干">
              <div class="form-item">
                <el-form-item prop="tetea_pra_title">
                  <!-- <el-input
                    v-model.trim="practiseForm.tetea_pra_title"
                    maxlength="100"
                    placeholder="请输入"
                  /> -->
                  <try-editorlatex
                      class="tinymce"
                      v-model="practiseForm.tetea_pra_title"
                  ></try-editorlatex>
                </el-form-item>
                <!-- <el-upload
                  class="img-uploader"
                  :action="$upload.imgAction"
                  :headers="$upload.header"
                  :show-file-list="false"
                  :accept="$upload.imgAccept"
                  :before-upload="$beforImgUpload"
                  :on-success="
                    (res) => {
                      return uploadImgSuccess(
                        res,
                        'practiseForm.tetea_pra_title_image'
                      );
                    }
                  "
                >
                  <div
                    class="img-box"
                    v-if="practiseForm.tetea_pra_title_image"
                    @click.stop
                  >
                    <el-image
                      style="width: 46px; height: 46px"
                      :src="formatfile(practiseForm.tetea_pra_title_image)"
                      :preview-src-list="[
                        formatfile(practiseForm.tetea_pra_title_image),
                      ]"
                    />
                    <i
                      class="del iconfont"
                      @click.stop="
                        practiseForm = {
                          ...practiseForm,
                          tetea_pra_title_image: '',
                        }
                      "
                      >&#xe63e;</i
                    >
                  </div>
                  <div class="upload-wrapper" v-else>
                    <i class="iconfont">&#xe63d;</i>
                  </div>
                </el-upload> -->
              </div>
            </el-form-item>
            <el-form-item label="是否组合题">
              <el-radio-group
                  v-model="practiseForm.tetea_pra_ismakeup"
                  @input="checkIsCompose"
              >
                <el-radio :label="20">否</el-radio>
                <el-radio :label="10">是</el-radio>
              </el-radio-group>
              <!-- 组合题添加小题 -->
              <el-button
                  v-if="practiseForm.tetea_pra_ismakeup == 10"
                  type="custom_primary"
                  size="medium"
                  @click="appendQues"
              >
                + 继续加题
              </el-button>
            </el-form-item>
            <div
                :class="{
                'compose-group': practiseForm.tetea_pra_ismakeup == 10,
                'question-data': practiseForm.tetea_pra_ismakeup == 20,
              }"
                v-for="(que, que_index) in practiseForm.option_arr"
                :key="que_index"
            >
              <!-- 组合题 -->
              <template v-if="practiseForm.tetea_pra_ismakeup == 10">
                <el-form-item label="小题干">
                  <div class="form-item">
                    <el-form-item
                        :prop="
                        'option_arr.' + que_index + '.tetea_children_title'
                      "
                        :rules="{
                        trigger: 'blur',
                        validator: function (rule, value, callback) {
                          if (
                            !que.tetea_children_title &&
                            !que.tetea_children_image
                          ) {
                            // 未上传文件
                            callback('请填写题干或者上传题干图片');
                          } else {
                            callback();
                          }
                        },
                      }"
                    >
                      <try-editorlatex
                          v-model="que.tetea_children_title"
                      ></try-editorlatex>
                    </el-form-item>
                    <!-- <el-upload
                      class="img-uploader"
                      :action="$upload.imgAction"
                      :headers="$upload.header"
                      :show-file-list="false"
                      :accept="$upload.imgAccept"
                      :before-upload="$beforImgUpload"
                      :on-success="
                        (res) => {
                          return uploadImgSuccess(
                            res,
                            `practiseForm.option_arr.${que_index}.tetea_children_image`
                          );
                        }
                      "
                    >
                      <div
                        class="img-box"
                        v-if="que.tetea_children_image"
                        @click.stop=""
                      >
                        <el-image
                          style="width: 46px; height: 46px"
                          :src="formatfile(que.tetea_children_image)"
                          :preview-src-list="[
                            formatfile(que.tetea_children_image),
                          ]"
                        />
                        <i
                          class="del iconfont"
                          @click.stop="
                            que.tetea_children_image = '';
                            $forceUpdate();
                          "
                          >&#xe63e;</i
                        >
                      </div>
                      <div class="upload-wrapper" v-else>
                        <i class="iconfont">&#xe63d;</i>
                      </div>
                    </el-upload> -->
                    <!-- 删除选项 -->
                    <el-button
                        class="iconfont mini"
                        type="custom_primary"
                        circle
                        @click="removeQues(que_index)"
                        v-if="
                        practiseForm.option_arr &&
                        practiseForm.option_arr.length > 1
                      "
                    >
                      &#xe620;
                    </el-button>
                  </div>
                </el-form-item>
                <!-- 小题数据 -->
                <div class="subtopic-data">
                  <!-- 题目类型 -->
                  <el-form-item
                      label="选择类型"
                      :prop="`option_arr.${que_index}.syque_typ_id`"
                      :rules="{
                      required: true,
                      message: '请选择题目类型',
                      trigger: 'change',
                    }"
                  >
                    <el-select
                        v-model="que.syque_typ_id"
                        placeholder="请选择"
                        @change="chooseQuesType($event, que_index)"
                    >
                      <template v-for="item in questionType">
                        <el-option
                            :key="item.syque_typ_id"
                            :label="item.syque_typ_name"
                            :value="item.syque_typ_id"
                            v-if="item.syque_typ_id != 14"
                        />
                      </template>
                    </el-select>
                  </el-form-item>
                  <!-- 选择题选项 -->
                  <el-form-item
                      label="选项"
                      v-show="isChoice(que.syque_typ_id) && que.syque_typ_id"
                  >
                    <div class="question-options">
                      <!-- 多选选项 -->
                      <el-checkbox-group
                          v-model="que.answer"
                          v-if="que.syque_typ_id == 2"
                      >
                        <ul class="option-list">
                          <li
                              v-for="(opt, opt_index) in que.question_item"
                              :key="opt_index"
                          >
                            <div class="option">
                              <span class="option-no">
                                {{ opt_index | changeEnChart }}
                              </span>
                              <el-form-item
                                  :prop="`option_arr.${que_index}.question_item.${opt_index}.tetea_pra_ite_title`"
                                  :rules="{
                                  required: false,
                                  trigger: 'blur',
                                  validator: function (rule, value, callback) {
                                    if (
                                      !opt.tetea_pra_ite_title &&
                                      !opt.tetea_pra_ite_image
                                    ) {
                                      callback('请填写选项内容或者选项图片');
                                    } else {
                                      callback();
                                    }
                                  },
                                }"
                              >
                                <try-editorlatex
                                    v-model="opt.tetea_pra_ite_title"
                                ></try-editorlatex>
                              </el-form-item>
                              <!-- <el-upload
                                class="img-uploader"
                                :action="$upload.imgAction"
                                :headers="$upload.header"
                                :show-file-list="false"
                                :accept="$upload.imgAccept"
                                :before-upload="$beforImgUpload"
                                :on-success="
                                  (res) => {
                                    return uploadImgSuccess(
                                      res,
                                      `practiseForm.option_arr.${que_index}.question_item.${opt_index}.tetea_pra_ite_image`
                                    );
                                  }
                                "
                              >
                                <div
                                  class="img-box"
                                  v-if="opt.tetea_pra_ite_image"
                                  @click.stop
                                >
                                  <el-image
                                    style="width: 46px; height: 46px"
                                    :src="formatfile(opt.tetea_pra_ite_image)"
                                    :preview-src-list="[
                                      formatfile(opt.tetea_pra_ite_image),
                                    ]"
                                  />
                                  <i
                                    class="del iconfont"
                                    @click.stop="opt.tetea_pra_ite_image = ''"
                                    >&#xe63e;</i
                                  >
                                </div>
                                <div class="upload-wrapper" v-else>
                                  <i class="iconfont">&#xe63d;</i>
                                </div>
                              </el-upload> -->
                              <!-- 删除选项 -->
                              <el-button
                                  class="iconfont mini"
                                  type="custom_primary"
                                  circle
                                  @click="
                                  removeQueOption_pra(que_index, opt_index)
                                "
                                  v-if="
                                  que.question_item &&
                                  que.question_item.length > 2
                                "
                              >
                                &#xe620;
                              </el-button>
                            </div>
                            <el-checkbox :label="opt_index"
                            >设为正确答案</el-checkbox
                            >
                          </li>
                        </ul>
                      </el-checkbox-group>
                      <!-- 单选选项 -->
                      <el-radio-group v-model="que.answer" v-else>
                        <ul class="option-list">
                          <li
                              v-for="(opt, opt_index) in que.question_item"
                              :key="opt_index"
                          >
                            <div class="option">
                              <span class="option-no">
                                {{ opt_index | changeEnChart }}
                              </span>
                              <el-form-item
                                  :prop="`option_arr.${que_index}.question_item.${opt_index}.tetea_pra_ite_title`"
                                  :rules="{
                                  required: false,
                                  trigger: 'blur',
                                  validator: function (rule, value, callback) {
                                    if (
                                      !opt.tetea_pra_ite_title &&
                                      !opt.tetea_pra_ite_image
                                    ) {
                                      callback('请填写选项内容或者选项图片');
                                    } else {
                                      callback();
                                    }
                                  },
                                }"
                              >
                                <try-editorlatex
                                    v-model="opt.tetea_pra_ite_title"
                                ></try-editorlatex>
                              </el-form-item>
                              <!-- <el-upload
                                class="img-uploader"
                                :action="$upload.imgAction"
                                :headers="$upload.header"
                                :show-file-list="false"
                                :accept="$upload.imgAccept"
                                :before-upload="$beforImgUpload"
                                :on-success="
                                  (res) => {
                                    return uploadImgSuccess(
                                      res,
                                      `practiseForm.option_arr.${que_index}.question_item.${opt_index}.tetea_pra_ite_image`
                                    );
                                  }
                                "
                              >
                                <div
                                  class="img-box"
                                  v-if="opt.tetea_pra_ite_image"
                                  @click.stop
                                >
                                  <el-image
                                    style="width: 46px; height: 46px"
                                    :src="formatfile(opt.tetea_pra_ite_image)"
                                    :preview-src-list="[
                                      formatfile(opt.tetea_pra_ite_image),
                                    ]"
                                  />
                                  <i
                                    class="del iconfont"
                                    @click.stop="opt.tetea_pra_ite_image = ''"
                                    >&#xe63e;</i
                                  >
                                </div>
                                <div class="upload-wrapper" v-else>
                                  <i class="iconfont">&#xe63d;</i>
                                </div>
                              </el-upload> -->
                              <!-- 删除选项 -->
                              <el-button
                                  class="iconfont mini"
                                  type="custom_primary"
                                  circle
                                  @click="
                                  removeQueOption_pra(que_index, opt_index)
                                "
                                  v-if="
                                  que.question_item &&
                                  que.question_item.length > 2
                                "
                              >
                                &#xe620;
                              </el-button>
                            </div>
                            <el-radio :label="opt_index">设为正确答案</el-radio>
                          </li>
                        </ul>
                      </el-radio-group>
                      <el-button
                          type="custom_primary"
                          size="medium"
                          @click="appendQueOption_pra(que_index)"
                      >
                        + 继续添加项
                      </el-button>
                    </div>
                  </el-form-item>
                  <!-- 非选择题答案 -->
                  <el-form-item
                      v-show="!isChoice(que.syque_typ_id) && que.syque_typ_id"
                      label="答案"
                      v-for="(opt, opt_index) in que.question_item"
                      :key="opt_index"
                      :prop="`option_arr.${que_index}.question_item.${opt_index}.tetea_pra_ite_title`"
                      :rules="{
                      required: false,
                      trigger: 'blur',
                      validator: function (rule, value, callback) {
                        if (
                          !opt.tetea_pra_ite_title &&
                          !opt.tetea_pra_ite_image
                        ) {
                          callback('请填写答案');
                        }
                        callback();
                      },
                    }"
                  >
                    <try-editorlatex
                        v-model="opt.tetea_pra_ite_title"
                        placeholder="多项填空题答案使用英文 , 隔开"
                    ></try-editorlatex>
                  </el-form-item>
                  <!-- 解析 -->
                  <el-form-item label="解析说明">
                    <!-- <el-input
                      v-model.trim="que.tetea_pra_analysis"
                      type="text"
                      maxlength="240"
                      placeholder="请输入"
                    /> -->
                    <try-editorlatex
                        class="tinymce"
                        v-model="que.tetea_pra_analysis"
                    ></try-editorlatex>
                  </el-form-item>
                  <!-- 知识点 -->
                  <el-form-item
                      label="绑定知识点"
                      :prop="`option_arr.${que_index}.tetea_kno_id`"
                  >
                    <el-select v-model="que.tetea_kno_id" placeholder="请选择">
                      <el-option
                          v-for="item in prepareKnowledge"
                          :key="item.tetea_kno_id"
                          :label="item.tetea_kno_title"
                          :value="item.tetea_kno_id"
                      />
                    </el-select>
                  </el-form-item>
                </div>
              </template>
              <!-- 非组合题 -->
              <template v-if="practiseForm.tetea_pra_ismakeup == 20">
                <!-- 题目类型 -->
                <el-form-item
                    label="选择类型"
                    :prop="`option_arr.${que_index}.syque_typ_id`"
                    :rules="{
                    required: true,
                    message: '请选择题目类型',
                    trigger: 'change',
                  }"
                >
                  <el-select
                      v-model="que.syque_typ_id"
                      placeholder="请选择"
                      @change="chooseQuesType($event, que_index)"
                  >
                    <template v-for="item in questionType">
                      <el-option
                          :key="item.syque_typ_id"
                          :label="item.syque_typ_name"
                          :value="item.syque_typ_id"
                          v-if="item.syque_typ_id != 14"
                      />
                    </template>
                  </el-select>
                </el-form-item>
                <!-- 选择题选项 -->
                <el-form-item
                    label="选项"
                    v-show="isChoice(que.syque_typ_id) && que.syque_typ_id"
                >
                  <div class="question-options">
                    <!-- 多选选项 -->
                    <el-checkbox-group
                        v-model="que.answer"
                        v-if="que.syque_typ_id == 2"
                    >
                      <ul class="option-list">
                        <li
                            v-for="(opt, opt_index) in que.question_item"
                            :key="opt_index"
                        >
                          <div class="option">
                            <span class="option-no">
                              {{ opt_index | changeEnChart }}
                            </span>
                            <el-form-item
                                :prop="`option_arr.${que_index}.question_item.${opt_index}.tetea_pra_ite_title`"
                                :rules="{
                                required: false,
                                trigger: 'blur',
                                validator: function (rule, value, callback) {
                                  if (
                                    !opt.tetea_pra_ite_title &&
                                    !opt.tetea_pra_ite_image
                                  ) {
                                    callback('请填写选项内容或者选项图片');
                                  } else {
                                    callback();
                                  }
                                },
                              }"
                            >
                              <try-editorlatex
                                  v-model="opt.tetea_pra_ite_title"
                              ></try-editorlatex>
                            </el-form-item>
                            <!-- <el-upload
                              class="img-uploader"
                              :action="$upload.imgAction"
                              :headers="$upload.header"
                              :show-file-list="false"
                              :accept="$upload.imgAccept"
                              :before-upload="$beforImgUpload"
                              :on-success="
                                (res) => {
                                  return uploadImgSuccess(
                                    res,
                                    `practiseForm.option_arr.${que_index}.question_item.${opt_index}.tetea_pra_ite_image`
                                  );
                                }
                              "
                            >
                              <div
                                class="img-box"
                                v-if="opt.tetea_pra_ite_image"
                                @click.stop
                              >
                                <el-image
                                  style="width: 46px; height: 46px"
                                  :src="formatfile(opt.tetea_pra_ite_image)"
                                  :preview-src-list="[
                                    formatfile(opt.tetea_pra_ite_image),
                                  ]"
                                />
                                <i
                                  class="del iconfont"
                                  @click.stop="opt.tetea_pra_ite_image = ''"
                                  >&#xe63e;</i
                                >
                              </div>
                              <div class="upload-wrapper" v-else>
                                <i class="iconfont">&#xe63d;</i>
                              </div>
                            </el-upload> -->
                            <!-- 删除选项 -->
                            <el-button
                                class="iconfont mini"
                                type="custom_primary"
                                circle
                                @click="removeQueOption_pra(que_index, opt_index)"
                                v-if="
                                que.question_item &&
                                que.question_item.length > 2
                              "
                            >
                              &#xe620;
                            </el-button>
                          </div>
                          <el-checkbox :label="opt_index"
                          >设为正确答案</el-checkbox
                          >
                        </li>
                      </ul>
                    </el-checkbox-group>
                    <!-- 单选选项 -->
                    <el-radio-group v-model="que.answer" v-else>
                      <ul class="option-list">
                        <li
                            v-for="(opt, opt_index) in que.question_item"
                            :key="opt_index"
                        >
                          <div class="option">
                            <span class="option-no">
                              {{ opt_index | changeEnChart }}
                            </span>
                            <el-form-item
                                :prop="`option_arr.${que_index}.question_item.${opt_index}.tetea_pra_ite_title`"
                                :rules="{
                                required: false,
                                trigger: 'blur',
                                validator: function (rule, value, callback) {
                                  if (
                                    !opt.tetea_pra_ite_title &&
                                    !opt.tetea_pra_ite_image
                                  ) {
                                    callback('请填写选项内容或者选项图片');
                                  } else {
                                    callback();
                                  }
                                },
                              }"
                            >
                              <try-editorlatex
                                  v-model="opt.tetea_pra_ite_title"
                              ></try-editorlatex>
                            </el-form-item>
                            <!-- <el-upload
                              class="img-uploader"
                              :action="$upload.imgAction"
                              :headers="$upload.header"
                              :show-file-list="false"
                              :accept="$upload.imgAccept"
                              :before-upload="$beforImgUpload"
                              :on-success="
                                (res) => {
                                  return uploadImgSuccess(
                                    res,
                                    `practiseForm.option_arr.${que_index}.question_item.${opt_index}.tetea_pra_ite_image`
                                  );
                                }
                              "
                            >
                              <div
                                class="img-box"
                                v-if="opt.tetea_pra_ite_image"
                                @click.stop
                              >
                                <el-image
                                  style="width: 46px; height: 46px"
                                  :src="formatfile(opt.tetea_pra_ite_image)"
                                  :preview-src-list="[
                                    formatfile(opt.tetea_pra_ite_image),
                                  ]"
                                />
                                <i
                                  class="del iconfont"
                                  @click.stop="opt.tetea_pra_ite_image = ''"
                                  >&#xe63e;</i
                                >
                              </div>
                              <div class="upload-wrapper" v-else>
                                <i class="iconfont">&#xe63d;</i>
                              </div>
                            </el-upload> -->
                            <!-- 删除选项 -->
                            <el-button
                                class="iconfont mini"
                                type="custom_primary"
                                circle
                                @click="removeQueOption_pra(que_index, opt_index)"
                                v-if="
                                que.question_item &&
                                que.question_item.length > 2
                              "
                            >
                              &#xe620;
                            </el-button>
                          </div>
                          <el-radio :label="opt_index">设为正确答案</el-radio>
                        </li>
                      </ul>
                    </el-radio-group>
                    <el-button
                        type="custom_primary"
                        size="medium"
                        @click="appendQueOption_pra(que_index)"
                    >
                      + 继续添加项
                    </el-button>
                  </div>
                </el-form-item>
                <!-- 非选择题答案 -->
                <el-form-item
                    v-show="!isChoice(que.syque_typ_id) && que.syque_typ_id"
                    label="答案"
                    v-for="(opt, opt_index) in que.question_item"
                    :key="opt_index"
                    :prop="`option_arr.${que_index}.question_item.${opt_index}.tetea_pra_ite_title`"
                    :rules="{
                    required: false,
                    trigger: 'blur',
                    validator: function (rule, value, callback) {
                      if (
                        !opt.tetea_pra_ite_title &&
                        !opt.tetea_pra_ite_image
                      ) {
                        callback('请填写题目答案');
                      }
                      callback();
                    },
                  }"
                >
                  <try-editorlatex
                      v-model="opt.tetea_pra_ite_title"
                      placeholder="多项填空题答案使用英文 , 隔开"
                  ></try-editorlatex>
                </el-form-item>

                <!-- 解析 -->
                <el-form-item label="解析说明">
                  <!-- <el-input
                    v-model.trim="que.tetea_pra_analysis"
                    type="text"
                    maxlength="240"
                    placeholder="请输入"
                  /> -->
                  <try-editorlatex
                      class="tinymce"
                      v-model="que.tetea_pra_analysis"
                  ></try-editorlatex>
                </el-form-item>

                <!-- 知识点 -->
                <el-form-item
                    label="绑定知识点"
                    :prop="`option_arr.${que_index}.tetea_kno_id`"
                >
                  <el-select v-model="que.tetea_kno_id" placeholder="请选择">
                    <el-option
                        v-for="item in prepareKnowledge"
                        :key="item.tetea_kno_id"
                        :label="item.tetea_kno_title"
                        :value="item.tetea_kno_id"
                    />
                  </el-select>
                </el-form-item>
              </template>
            </div>
          </el-form>
        </div>
      </div>
      <div slot="footer">
        <el-button
            type="custom_info"
            size="small"
            @click="hideDialog('practise')"
        >取 消</el-button
        >
        <el-button
            type="custom_primary"
            size="small"
            @click="addPractise"
            :disabled="practiseLoad"
            v-loading="practiseLoad"
        >
          确 定
        </el-button>
      </div>
    </el-dialog>
    <!-- 课堂练习详情 -->
    <el-dialog
        :visible.sync="practiseDetailDialog"
        :class="{ calc: previewPrepare }"
        width="900px"
        title="课堂练习详情"
        @close="hideDialog('practiseDetail')"
    >
      <div class="dialog-question detail">
        <div class="dialog-question--practise dialog-scroll">
          <span class="question--type">{{
              practiseDetail.syque_typ_name
            }}</span>
          <div class="question--data">
            <h5 class="bold">
              <em
                  class="richinline"
                  v-html="practiseDetail.tetea_pra_title"
              ></em>
              <el-image
                  style="width: 60px; height: 38px"
                  v-if="practiseDetail.tetea_pra_title_image"
                  :src="formatfile(practiseDetail.tetea_pra_title_image)"
                  fit="cover"
                  :preview-src-list="[
                  formatfile(practiseDetail.tetea_pra_title_image),
                ]"
              />
            </h5>
            <!-- 组合题 -->
            <template v-if="practiseDetail.tetea_pra_ismakeup == 10">
              <p class="combination--title">
                {{ practiseDetail.tetea_pra_describe }}
              </p>
              <ol class="combination--subtopic">
                <li
                    class="subtopic-item"
                    v-for="(citem, cindex) in practiseDetail.option_arr"
                    :key="citem.tetea_pra_id"
                >
                  <span class="subtopic--num">（{{ cindex + 1 }}）</span>
                  <div class="subtopic--data">
                    <p class="subtopic--title">
                      <span
                          class="richinline"
                          v-html="citem.tetea_pra_title"
                      ></span>
                      <el-image
                          style="width: 60px; height: 38px"
                          v-if="citem.tetea_pra_title_image"
                          :src="formatfile(citem.tetea_pra_title_image)"
                          fit="cover"
                          :preview-src-list="[
                          formatfile(citem.tetea_pra_title_image),
                        ]"
                      />
                    </p>
                    <dl
                        class="subtopic--options"
                        v-if="
                        citem.syque_typ_id == 1 ||
                        citem.syque_typ_id == 2 ||
                        citem.syque_typ_id == 5
                      "
                    >
                      <dt
                          class="option"
                          v-for="o in citem.question_item"
                          :key="o.tetea_pra_ite_id"
                      >
                        <span
                        >{{ o.tetea_pra_ite_code }}.
                          <span
                              class="richinline"
                              v-html="o.tetea_pra_ite_title"
                          ></span>
                        </span>
                        <el-image
                            style="width: 54px; height: 32px"
                            v-if="o.tetea_pra_ite_image"
                            :src="formatfile(o.tetea_pra_ite_image)"
                            fit="cover"
                            :preview-src-list="[
                            formatfile(o.tetea_pra_ite_image),
                          ]"
                        />
                      </dt>
                    </dl>
                    <p class="subtopic--knowledge" v-if="citem.tetea_kno_title">
                      {{ citem.tetea_kno_title }}
                    </p>
                    <div
                        class="subtopic--answer"
                        v-if="
                        !$isEmpty(citem.sure_answer) || citem.tetea_pra_analysis
                      "
                    >
                      <p class="answer" v-if="!$isEmpty(citem.sure_answer)">
                        答案：<span
                          class="richinline"
                          v-html="citem.sure_answer.join(',')"
                      ></span>
                      </p>
                      <p
                          class="analysis richinline"
                          v-if="citem.tetea_pra_analysis"
                          v-html="citem.tetea_pra_analysis"
                      ></p>
                    </div>
                  </div>
                </li>
              </ol>
            </template>
            <!-- 其他题型 -->

            <template v-else-if="practiseDetail.option_arr">
              <ul
                  class="question--options"
                  v-if="
                  practiseDetail.syque_typ_id == 1 ||
                  practiseDetail.syque_typ_id == 2 ||
                  practiseDetail.syque_typ_id == 5
                "
              >
                <li
                    class="option"
                    v-for="o in practiseDetail.option_arr[0].question_item"
                    :key="o.tetea_pra_ite_id"
                >
                  <span>
                    {{ o.tetea_pra_ite_code }}.
                    <span
                        class="richinline"
                        v-html="o.tetea_pra_ite_title"
                    ></span>
                  </span>
                  <el-image
                      style="width: 54px; height: 32px"
                      v-if="o.tetea_pra_ite_image"
                      :src="formatfile(o.tetea_pra_ite_image)"
                      fit="cover"
                      :preview-src-list="[formatfile(o.tetea_pra_ite_image)]"
                  />
                </li>
              </ul>
              <p
                  class="question--knowledge"
                  v-if="practiseDetail.option_arr[0].tetea_kno_title"
              >
                {{ practiseDetail.option_arr[0].tetea_kno_title }}
              </p>
              <div class="question--answer">
                <p
                    class="answer"
                    v-if="!$isEmpty(practiseDetail.option_arr[0].sure_answer)"
                >
                  答案：<span
                    class="richinline"
                    v-html="practiseDetail.option_arr[0].sure_answer.join(',')"
                ></span>
                </p>
                <p
                    class="analysis richinline"
                    v-if="practiseDetail.option_arr[0].tetea_pra_analysis"
                    v-html="practiseDetail.option_arr[0].tetea_pra_analysis"
                ></p>
              </div>
            </template>
          </div>
        </div>
      </div>
    </el-dialog>

    <!-- 作业 新建||编辑 -->
    <el-dialog
        :title="(homeworkForm.tetea_hom_id && '编辑') || '新建'"
        :visible.sync="homeworkDialog"
        width="900px"
        :show-close="false"
        @close="hideDialog('homework')">    
      <div class="dialog-homework-form">
        <div class="dialog-scroll">
          <div class="form-wrapper">
            <el-form
                ref="homeworkForm"
                :model="homeworkForm"
                label-width="82px"
                :rules="homeworkRules"
            >
              <el-form-item label="标题" prop="tetea_hom_title">
                <el-input
                    v-model.trim="homeworkForm.tetea_hom_title"
                    maxlength="100"
                    placeholder="请输入"
                />
              </el-form-item>
              <el-form-item label="描述" prop="tetea_hom_remark">
                <el-input
                    v-model.trim="homeworkForm.tetea_hom_remark"
                    type="textarea"
                    maxlength="200"
                    placeholder="请输入"
                />
              </el-form-item>
              <el-form-item label="类型" prop="tetea_hom_type">
                <el-radio-group v-model="homeworkForm.tetea_hom_type">
                  <el-radio :label="1">家庭作业</el-radio>
                  <el-radio :label="2">校内作业</el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item label="选题">
                <el-select
                    v-model="homeworkForm.syque_typ_id"
                    placeholder="请选择题目类型"
                    @change="searchQues"
                >
                  <el-option
                      v-for="item in questionType"
                      :key="item.syque_typ_id"
                      :label="item.syque_typ_name"
                      :value="item.syque_typ_id"
                  />
                </el-select>
                <!-- 自定义下拉 queSelect 选中的题 valkey选中的key  title显示的文案 remoteMethod输入框输入的文字  reachBottom滚动触底  more是否更多-->
                <richSelect
                    style="margin-bottom: 12px"
                    :list="selectQues"
                    placeholder="请输入题目关键字"
                    v-model="homeworkForm.scque_id"
                    @queSelect="queSelect"
                    title="scque_title"
                    valkey="scque_id"
                    @remoteMethod="filterQues"
                    @reachBottom="loadMoreQues"
                    :more="this.selectQues.length < this.selectQuesPage.total"
                ></richSelect>
                <div class="homework-questions" v-if="atPresentQues.scque_id">
                  <ul class="dialog-question-list">
                    <li class="question-item">
                      <span class="question--type">{{
                          atPresentQues.syque_typ_name
                        }}</span>
                      <div class="question--data">
                        <h5 class="bold">
                          <span
                              class="richinline"
                              v-html="atPresentQues.scque_title"
                          ></span>
                          <el-image
                              style="width: 60px; height: 38px"
                              v-if="atPresentQues.scque_title_image"
                              :src="formatfile(atPresentQues.scque_title_image)"
                              fit="cover"
                              :preview-src-list="[
                              formatfile(atPresentQues.scque_title_image),
                            ]"
                          />
                        </h5>
                        <!-- 组合题 -->
                        <template v-if="atPresentQues.scque_ismakeup == 10">
                          <p class="combination--title">
                            {{ atPresentQues.scque_describe }}
                          </p>
                          <ol class="combination--subtopic">
                            <li
                                class="subtopic-item"
                                v-for="(
                                citem, cindex
                              ) in atPresentQues.children_question"
                                :key="citem.scque_id"
                            >
                              <span class="subtopic--num"
                              >（{{ cindex + 1 }}）</span
                              >
                              <div class="subtopic--data">
                                <p class="subtopic--title">
                                  <span
                                      class="richinline"
                                      v-html="citem.scque_title"
                                  ></span>
                                  <el-image
                                      style="width: 60px; height: 38px"
                                      v-if="citem.scque_title_image"
                                      :src="formatfile(citem.scque_title_image)"
                                      fit="cover"
                                      :preview-src-list="[
                                      formatfile(citem.scque_title_image),
                                    ]"
                                  />
                                </p>
                                <dl
                                    class="subtopic--options"
                                    v-if="
                                    citem.syque_typ_id == 1 ||
                                    citem.syque_typ_id == 2 ||
                                    citem.syque_typ_id == 5
                                  "
                                >
                                  <dt
                                      class="option"
                                      v-for="o in citem.question_item"
                                      :key="o.scque_ite_id"
                                  >
                                    <span
                                    >{{ o.scque_ite_code }}.
                                      <span
                                          class="richinline"
                                          v-html="o.scque_ite_title"
                                      ></span
                                      ></span>
                                    <el-image
                                        style="width: 54px; height: 32px"
                                        v-if="o.scque_ite_image"
                                        :src="formatfile(o.scque_ite_image)"
                                        fit="cover"
                                        :preview-src-list="[
                                        formatfile(o.scque_ite_image),
                                      ]"
                                    />
                                  </dt>
                                </dl>
                                <p
                                    class="subtopic--knowledge"
                                    v-if="citem.tetea_kno_title"
                                >
                                  {{ citem.tetea_kno_title }}
                                </p>
                                <div class="subtopic--answer">
                                  <p
                                      class="answer"
                                      v-if="!$isEmpty(citem.sure_answer)"
                                  >
                                    答案：<span
                                      class="richinline"
                                      v-html="citem.sure_answer.join(',')"
                                  ></span>
                                  </p>
                                  <p
                                      class="analysis"
                                      v-if="citem.scque_analysis"
                                  >
                                    <span
                                        class="richinline"
                                        v-html="citem.scque_analysis"
                                    ></span>
                                  </p>
                                </div>
                              </div>
                            </li>
                          </ol>
                        </template>
                        <!-- 其他题型 -->
                        <template v-else>
                          <ul
                              class="question--options"
                              v-if="
                              atPresentQues.syque_typ_id == 1 ||
                              atPresentQues.syque_typ_id == 2 ||
                              atPresentQues.syque_typ_id == 5
                            "
                          >
                            <li
                                class="option"
                                v-for="o in atPresentQues.children_question"
                                :key="o.scque_ite_id"
                            >
                              <span
                              >{{ o.scque_ite_code }}.
                                <span
                                    class="richinline"
                                    v-html="o.scque_ite_title"
                                ></span
                                ></span>
                              <el-image
                                  style="width: 54px; height: 32px"
                                  v-if="o.scque_ite_image"
                                  :src="formatfile(o.scque_ite_image)"
                                  fit="cover"
                                  :preview-src-list="[
                                  formatfile(o.scque_ite_image),
                                ]"
                              />
                            </li>
                          </ul>
                          <p
                              class="question--knowledge"
                              v-if="atPresentQues.tetea_kno_title"
                          >
                            {{ atPresentQues.tetea_kno_title }}
                          </p>
                          <div class="question--answer">
                            <p
                                class="answer"
                                v-if="!$isEmpty(atPresentQues.sure_answer)"
                            >
                              答案：<span
                                class="richinline"
                                v-html="atPresentQues.sure_answer.join(',')"
                            ></span>
                            </p>
                            <p
                                class="analysis"
                                v-if="atPresentQues.scque_analysis"
                            >
                              <span
                                  class="richinline"
                                  v-html="atPresentQues.scque_analysis"
                              ></span>
                            </p>
                          </div>
                        </template>
                      </div>
                    </li>
                  </ul>
                </div>
                <div>
                  <el-button
                      type="custom_primary"
                      size="small"
                      @click="appendHomeworkQue"
                  >+添加题</el-button
                  >
                </div>
              </el-form-item>
            </el-form>
          </div>
          <div class="homework-questions">
            <ul class="dialog-question-list">
              <li
                  class="question-item"
                  :class="{ combination: item.scque_ismakeup == 10 }"
                  v-for="(item, index) in homeworkQues"
                  :key="item.scque_id"
              >
                <span class="question--num"
                >{{ (index + 1 > 9 && index + 1) || `0${index + 1} ` }}.</span
                >
                <span class="question--type">{{ item.syque_typ_name }}</span>
                <div class="question--data">
                  <h5 class="bold">
                    <span class="richinline" v-html="item.scque_title"></span>
                    <el-image
                        style="width: 60px; height: 38px"
                        v-if="item.scque_title_image"
                        :src="formatfile(item.scque_title_image)"
                        fit="cover"
                        :preview-src-list="[formatfile(item.scque_title_image)]"
                    />
                  </h5>
                  <!-- 组合题 -->
                  <template v-if="item.scque_ismakeup == 10">
                    <p class="combination--title">{{ item.scque_describe }}</p>
                    <ol class="combination--subtopic">
                      <li
                          class="subtopic-item"
                          v-for="(citem, cindex) in item.children_question"
                          :key="citem.scque_id"
                      >
                        <span class="subtopic--num">（{{ cindex + 1 }}）</span>
                        <div class="subtopic--data">
                          <p class="subtopic--title">
                            <span
                                class="richinline"
                                v-html="citem.scque_title"
                            ></span>
                            <el-image
                                style="width: 60px; height: 38px"
                                v-if="citem.scque_title_image"
                                :src="formatfile(citem.scque_title_image)"
                                fit="cover"
                                :preview-src-list="[
                                formatfile(citem.scque_title_image),
                              ]"
                            />
                          </p>
                          <dl
                              class="subtopic--options"
                              v-if="
                              citem.syque_typ_id == 1 ||
                              citem.syque_typ_id == 2 ||
                              citem.syque_typ_id == 5
                            "
                          >
                            <dt
                                class="option"
                                v-for="o in citem.question_item"
                                :key="o.scque_ite_id"
                            >
                              <span
                              >{{ o.scque_ite_code }}.
                                <span
                                    class="richinline"
                                    v-html="o.scque_ite_title"
                                ></span
                                ></span>
                              <el-image
                                  style="width: 54px; height: 32px"
                                  v-if="o.scque_ite_image"
                                  :src="formatfile(o.scque_ite_image)"
                                  fit="cover"
                                  :preview-src-list="[
                                  formatfile(o.scque_ite_image),
                                ]"
                              />
                            </dt>
                          </dl>
                          <p
                              class="subtopic--knowledge"
                              v-if="citem.tetea_kno_title"
                          >
                            {{ citem.tetea_kno_title }}
                          </p>
                          <div class="subtopic--answer">
                            <p
                                class="answer"
                                v-if="!$isEmpty(citem.sure_answer)"
                            >
                              答案：<span
                                class="richinline"
                                v-html="citem.sure_answer.join(',')"
                            ></span>
                            </p>
                            <p class="analysis" v-if="citem.scque_analysis">
                              <span
                                  class="richinline"
                                  v-html="citem.scque_analysis"
                              ></span>
                            </p>
                          </div>
                        </div>
                      </li>
                    </ol>
                  </template>
                  <!-- 其他题型 -->
                  <template v-else>
                    <ul
                        class="question--options"
                        v-if="
                        item.syque_typ_id == 1 ||
                        item.syque_typ_id == 2 ||
                        item.syque_typ_id == 5
                      "
                    >
                      <li
                          class="option"
                          v-for="o in item.children_question"
                          :key="o.scque_ite_id"
                      >
                        <span
                        >{{ o.scque_ite_code }}.
                          <span
                              class="richinline"
                              v-html="o.scque_ite_title"
                          ></span
                          ></span>
                        <el-image
                            style="width: 54px; height: 32px"
                            v-if="o.scque_ite_image"
                            :src="formatfile(o.scque_ite_image)"
                            fit="cover"
                            :preview-src-list="[formatfile(o.scque_ite_image)]"
                        />
                      </li>
                    </ul>
                    <p class="question--knowledge" v-if="item.tetea_kno_title">
                      {{ item.tetea_kno_title }}
                    </p>
                    <div class="question--answer">
                      <p class="answer" v-if="!$isEmpty(item.sure_answer)">
                        答案：<span
                          class="richinline"
                          v-html="item.sure_answer.join(',')"
                      ></span>
                      </p>
                      <p class="analysis" v-if="item.scque_analysis">
                        <span
                            class="richinline"
                            v-html="item.scque_analysis"
                        ></span>
                      </p>
                    </div>
                  </template>
                </div>
                <div class="operate-group iconfont">
                  <!-- 移除作业题 -->
                  <el-button
                      type="custom_primary"
                      circle
                      @click="removeHomeworkQue(index)"
                  >&#xe620;</el-button
                  >
                  <!-- 作业题排序调整 -->
                  <template v-if="homeworkQues.length > 1">
                    <!-- 上移 -->
                    <el-button
                        class="lighter"
                        type="custom_info"
                        circle
                        v-if="index > 0"
                        @click="changeSort('u', index)"
                    >&#xe657;</el-button
                    >
                    <!-- 下移 -->
                    <el-button
                        class="lighter"
                        type="custom_info"
                        circle
                        v-if="index < homeworkQues.length - 1"
                        @click="changeSort('d', index)"
                    >&#xe645;</el-button
                    >
                  </template>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div slot="footer">
        <el-button
            type="custom_info"
            size="small"
            @click="hideDialog('homework')"
        >取 消</el-button
        >
        <el-button
            type="custom_primary"
            size="small"
            @click="addHomework"
            :disabled="homeworkLoad"
            v-loading="homeworkLoad"
        >
          确 定
        </el-button>
      </div>
    </el-dialog>
    <!-- 作业详情 -->
    <el-dialog
        :visible.sync="homeworkDetailDialog"
        :class="{ calc: previewPrepare }"
        width="900px"
        title="作业详情"
        @close="hideDialog('homeworkDetail')"
    >
      <div class="dialog-question detail" v-if="!$isEmpty(homeworkDetail)">
        <div class="dialog-scroll">
          <div class="dialog-question--head">
            <p class="dialog-question--title">
              标题：<span>{{ homeworkDetail.tetea_hom_title }}</span>
            </p>
            <div class="dialog-question--desc">
              <span>描述：</span>
              <p
                  v-html="
                  homeworkDetail.tetea_hom_remark
                    .replace(/\n|\r\n/g, '<br>')
                    .replace(/ /g, '&nbsp;')
                "
              ></p>
            </div>
          </div>
          <ul class="dialog-question-list">
            <li
                class="question-item"
                :class="{ combination: item.scque_ismakeup == 10 }"
                v-for="(item, index) in homeworkDetail.question"
                :key="item.scque_id"
            >
              <span class="question--num"
              >{{ (index + 1 > 9 && index + 1) || `0${index + 1} ` }}.</span
              >
              <span class="question--type">{{ item.syque_typ_name }}</span>
              <div class="question--data">
                <h5 class="bold">
                  <span class="richinline" v-html="item.scque_title"></span>
                  <el-image
                      style="width: 60px; height: 38px"
                      v-if="item.scque_title_image"
                      :src="formatfile(item.scque_title_image)"
                      fit="cover"
                      :preview-src-list="[formatfile(item.scque_title_image)]"
                  />
                </h5>
                <!-- 组合题 -->
                <template v-if="item.scque_ismakeup == 10">
                  <p class="combination--title">{{ item.scque_describe }}</p>
                  <ol class="combination--subtopic">
                    <li
                        class="subtopic-item"
                        v-for="(citem, cindex) in item.children_question"
                        :key="citem.scque_id"
                    >
                      <span class="subtopic--num">（{{ cindex + 1 }}）</span>
                      <div class="subtopic--data">
                        <p class="subtopic--title">
                          <span
                              class="richinline"
                              v-html="citem.scque_title"
                          ></span>
                          <el-image
                              style="width: 60px; height: 38px"
                              v-if="citem.scque_title_image"
                              :src="formatfile(citem.scque_title_image)"
                              fit="cover"
                              :preview-src-list="[
                              formatfile(citem.scque_title_image),
                            ]"
                          />
                        </p>
                        <dl
                            class="subtopic--options"
                            v-if="
                            citem.syque_typ_id == 1 ||
                            citem.syque_typ_id == 2 ||
                            citem.syque_typ_id == 5
                          "
                        >
                          <dt
                              class="option"
                              v-for="o in citem.question_item"
                              :key="o.scque_ite_id"
                          >
                            <span
                            >{{ o.scque_ite_code }}.
                              <span
                                  class="richinline"
                                  v-html="o.scque_ite_title"
                              ></span
                              ></span>
                            <el-image
                                style="width: 54px; height: 32px"
                                v-if="o.scque_ite_image"
                                :src="formatfile(o.scque_ite_image)"
                                fit="cover"
                                :preview-src-list="[
                                formatfile(o.scque_ite_image),
                              ]"
                            />
                          </dt>
                        </dl>
                        <p
                            class="subtopic--knowledge"
                            v-if="citem.tetea_kno_title"
                        >
                          {{ citem.tetea_kno_title }}
                        </p>
                        <div class="subtopic--answer">
                          <p class="answer" v-if="!$isEmpty(citem.sure_answer)">
                            答案：<span
                              class="richinline"
                              v-html="citem.sure_answer.join(',')"
                          ></span>
                          </p>
                          <p class="analysis" v-if="citem.scque_analysis">
                            <span
                                class="richinline"
                                v-html="citem.scque_analysis"
                            ></span>
                          </p>
                        </div>
                      </div>
                    </li>
                  </ol>
                </template>
                <!-- 其他题型 -->
                <template v-else>
                  <ul
                      class="question--options"
                      v-if="
                      item.syque_typ_id == 1 ||
                      item.syque_typ_id == 2 ||
                      item.syque_typ_id == 5
                    "
                  >
                    <li
                        class="option"
                        v-for="o in item.children_question"
                        :key="o.scque_ite_id"
                    >
                      <span class="flex">
                        {{ o.scque_ite_code }}.
                        <span
                            class="richinline"
                            v-html="o.scque_ite_title"
                        ></span
                        ></span>
                      <el-image
                          style="width: 54px; height: 32px"
                          v-if="o.scque_ite_image"
                          :src="formatfile(o.scque_ite_image)"
                          fit="cover"
                          :preview-src-list="[formatfile(o.scque_ite_image)]"
                      />
                    </li>
                  </ul>
                  <p class="question--knowledge" v-if="item.tetea_kno_title">
                    {{ item.tetea_kno_title }}
                  </p>
                  <div class="question--answer">
                    <p class="answer" v-if="!$isEmpty(item.sure_answer)">
                      答案：<span
                        class="richinline"
                        v-html="item.sure_answer.join(',')"
                    ></span>
                    </p>
                    <p class="analysis" v-if="item.scque_analysis">
                      <span
                          class="richinline"
                          v-html="item.scque_analysis"
                      ></span>
                    </p>
                  </div>
                </template>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div v-else class="dialog-question detail" style="padding:180px 0;" v-loading="true"></div>
    </el-dialog>
    <!-- 课件预览 -->
    <el-dialog
        :title="
        (quoteCourseware.length && quoteCourseware[0].tetea_cou_title) || ''
      "
        :class="{ calc: previewPrepare }"
        :visible.sync="previewDialog"
        width="80%"
        :show-close="false"
        @close="previewDialog = false"
    >
      <div class="courseware-view" v-if="quoteCourseware.length">
        <iframe
            v-if="quoteCourseware[0].tetea_cou_type != 'pdf'"
            :src="pptCourseware"
        />
        <iframe
            v-if="quoteCourseware[0].tetea_cou_type == 'pdf'"
            :src="pdfCourseware"
        />
      </div>
    </el-dialog>
    <!-- 图片识别 试卷切题 -->
    <cuttingProblem style="position: fixed;z-index: 1000;" v-model="showCutting" :emphasis="prepareKnowledge" :loadingall="loadingall" @hideimgup="showCutting=false" @submit="submitCuttingProblem"></cuttingProblem>
  </section>
</template>

<script>
import * as teach from "@api/content";
import {addTeaTeaching,getTeaTeachingInfo,getGroupData} from "@api/content";

import {
  formatTime,
  formatTimeStamp,
  changeEnChart,
  enChartToNum,
  formatFileType,
  formatFile,
} from "@utils";
import { mapState } from "vuex";
import { API_ROOT } from "@config";
import customPagination from "@comp/customPagination";
import UploadCom from "@/components/UploadCom.vue";
import cuttingProblem from "@comp/cuttingProblem";
import { $ocradd} from "@api/resources";
export default {
  name: "content_prepare",
  components: { customPagination ,UploadCom,cuttingProblem},
  computed: {
    ...mapState("common", [
      "gradeList",
      "subjectList",
      "classList",
      "questionType",
    ]),
    /* 文件类型 */
    fileType() {
      return function (val) {
        return formatFileType(val);
      };
    },

    /* 上课时间 - 单元格动态类名 */
    weekTrendsClass() {
      return function (
          { sccla_sch_tim_starttime: startTime, sccla_sch_tim_endtime: endTime },
          val,
          key
      ) {
        let date = formatTime(val.date * 1000, "YYYY/MM/DD"); // 课程日期
        let currentTime = formatTimeStamp(new Date()); // 当前时间戳
        startTime = formatTimeStamp(`${date} ${startTime}`); // 课程开始时间戳
        endTime = formatTimeStamp(`${date} ${endTime}`); // 课程结束时间戳

        let { data } = val;
        let className = [];
        let condition = this.$isEmpty(data);
        let isEnd = currentTime > endTime;

        if (isEnd && condition) className.push("over"); // 当前时间之前的课程单元格
        if (!condition && val.state == 1) className.push("completed"); // 已结束课程
        if (!condition && val.state == 2)
          (data.is_type == 1 && className.push("oneself")) ||
          className.push("employ"); // 未结束课程内容
        if (this.currentPopover == key) className.push("show");
        return className;
      };
    },
    formatfile() {
      return function (url) {
        return formatFile(url);
      };
    },
    /** 判断是否是选择题 */
    isChoice() {
      const choiceTypeIds = [1, 2, 5];
      return function (val) {
        return choiceTypeIds.indexOf(val) != -1;
      };
    },
    /** 判断是否是组合题 */
    isCompose() {
      return function (val) {
        return val == 10;
      };
    },
    pptCourseware() {
      if (!this.quoteCourseware.length) return "";
      return `https://view.officeapps.live.com/op/view.aspx?src=${formatFile(
          this.quoteCourseware[0].tetea_cou_url
      )}`;
    },
    pdfCourseware() {
      if (!this.quoteCourseware.length) return "";
      let file = formatFile(this.quoteCourseware[0].tetea_cou_url);
      let url = `pdf/web/viewer.html?file=${encodeURIComponent(file)}`;
      return url;
    },
  },
  data() {
    /* 练习题标题 */
    const teteaPraTitleValidate = (rule, value, callback) => {
      if (
          !this.practiseForm.tetea_pra_title &&
          !this.practiseForm.tetea_pra_title_image
      ) {
        // 未上传文件
        callback("请填写题干或者上传题干图片");
      }
      callback();
    };
    /* 文件上传校验规则 */
    const fileMustUpload = (rule, value, callback) => {
      if (!this.uploadForm.tetea_cou_url) {
        // 未上传文件
        callback("请上传课件资源");
      }
      callback();
    };
    return {
      teteaId: null, // 备课关联id
      /* 筛选备课绑定章节 */
      fileList: [], //知识点导入列表
      practiseImportfileList: [],
      searchDialog: false, // 显示筛选弹窗
      searchTitle: "", // 筛选章节文本
      searchData: {}, // 筛选数据集合
      chapterData: [], // 章
      chapterKey: "", // 筛选章（关键字）
      jointData: [], // 节
      jointKey: "", // 筛选节（关键字）
      /* 选择上课时间 */
      timeDialog: false, // 选择上课时间弹窗显示
      teacherLessons: [], // 教师周课程表
      currentPopover: "", // 当前选中占课单元格
      changePopover: false, // 执行单元格切换
      lessonForm: {}, // 占课填充表单
      teachTime: {}, // 选中的上课时间
      popoverLoad: false,
      /* 自定义周日历 */
      currentYear: "", // 当前年份
      currentMonth: "", // 当前月份
      currentDay: "", // 当前日期
      currentWeek: "", // 当前星期几
      week: ["一", "二", "三", "四", "五", "六", "日"],
      weekDays: [], // 一周的天数
      /* 备课课件 */
      courseware: [],
      uploadDialog: false, // 显示课件上传弹窗
      uploadForm: {
        tetea_is_share_type: 2,
      }, // 课件上传表单
      coursewareList: [], // 课件列表
      coursewareLoad: false,
      uploadRules: {
        tetea_cou_title: [
          { required: true, message: "请填写课件名称", trigger: "blur" },
        ],
        tetea_cou_remark: [
          { required: true, message: "请填写课件描述", trigger: "blur" },
        ],
        tetea_cou_url: [{ validator: fileMustUpload, trigger: "change" }],
      },
      /* 知识点 */
      prepareKnowledge: [],
      btnload: false,
      knowledgeImportDialog: false, // 知识点导入上传
      knowledgeImportForm: {}, // 知识点表单

      practiseImportDialog: false, // 知识点导入上传
      practiseImportForm: {}, // 知识点表单
      knowledgeList: [], //导入list

      knowledgeDialog: false, // 显示知识点新增、编辑弹窗
      knowledgeDetailDialog: false, // 显示知识点详情弹窗
      knowledgeForm: {}, // 知识点表单
      knowledgeDetail: {}, // 知识点详情
      knowledgeLoad: false,
      upFileOption: {}, //上传文件配置
      knowledgeRules: {
        tetea_kno_title: [
          { required: true, message: "请填写知识点标题", trigger: "blur" },
        ],
        tetea_kno_content: [
          { required: true, message: "请填写知识点内容", trigger: "blur" },
        ],
      },
      /* 课堂作业 */
      preparePractise: [],
      practiseDialog: false, // 显示课堂练习新增、编辑弹窗
      practiseDetailDialog: false, // 显示课堂练习详情弹窗
      practiseForm: {
        tetea_pra_ismakeup: 20,
        tetea_pra_title: "",
        option_arr: [{}],
      }, // 课堂练习表单
      practiseDetail: {}, // 课堂练习详情
      practiseLoad: false,
      practiseRules: {
        tetea_pra_title: [
          { validator: teteaPraTitleValidate, trigger: "change" },
        ],
      },
      /* 作业 */
      prepareHomework: {},
      prepareSchoolHomework: {},
      homeworkDialog: false, // 显示作业新增、编辑弹窗
      homeworkDetailDialog: false, // 显示作业详情弹窗
      homeworkForm: {
        tetea_hom_type:1,
      }, // 作业表单
      hom_id: 0,
      homeworkDetail: {}, // 作业详情
      homeworkQues: [], // 作业内选中题目
      selectQues: [], // 可选题目列表
      homeworkLoad: false,
      /* 可选题目分页参数 */
      selectQuesPage: {
        index: 1,
        total: 0,
      },
      selectQuesKey: "", // 可选题目筛选关键字
      homeworkRules: {
        tetea_hom_title: [
          { required: true, message: "请填写作业标题", trigger: "blur" },
        ],
        tetea_hom_remark: [
          { required: true, message: "请填写作业描述", trigger: "change" },
        ],
        tetea_hom_type: [
          { required: true, message: "请选择作业类型", trigger: "change" },
        ],
      },
      //新建切换题目小题展示
      atPresentQues: {},
      /* ----------------------------- 以下是引用备课数据相关参数变量 ----------------------------- */
      quote_doing: false, // 引用操作是否正在执行
      sccou_cha_id: null, // 章节id
      preparesFrom: 1, // 备课数据来源：1、学校 2、平台
      preparesData: [], // 待引用备课数据列表
      preparesPage: {
        pageindex: 1,
        pagesize: 12,
        total: 0,
      },
      previewPrepare: false, // 预览窗口是否打开
      quoteCourseware: [], // 课件信息
      quoteKnowledge: [], // 知识点列表
      quotePractise: [], // 课堂练习
      quoteHomework: {}, // 家庭作业
      quoteSchoolHomework: {}, // 校内作业
      quoteLoad: false,
      quoteLoad1: false,
      quoteLoad2: false,
      quoteLoad3: false,
      quoteLoad4: false,
      previewDialog: false, // 预览课件弹窗
      //导入按钮等待
      download1: false,
      download2: false,
      //批量加入
      allCheck: false,
      //部分选中
      someCheck: false,
      //是否显示整个页面
      showQuestionDoc: false,
      submitQuestionDocload:false,
      // 导入数量
      succNum:0,
      //导入的题库数据
      questionDoc:[],
      sccou_id:0,
      showCutting:false,//试卷识别弹窗
      loadingall:false,//等待弹窗,
      allloading:false,//大窗等待
    };
  },
  created() {
    this.teteaId = this.$route.params.teteaId;
    // if (!this.teteaId) this.searchDialog = false;
    //  else this.initData();

    this.getDetail();

    this.initData();
  },
  mounted() {
    document.addEventListener("click", (e) => {
      let el = e.target.className.split(" ");
      if (el[0] != "vacancy" && this.changePopover) {
        this.changePopover = false;
        this.hidePopover();
      }
    });
  },
  watch: {},
  methods: {
    /** 修改前获取详情信息 */
    async getDetail() {

      if(this.$route.params.teteaId){
        this.teteaId = this.$route.params.teteaId;
        const {
          data: resdata,
          status: status,
          msg: msg,
        } = await getTeaTeachingInfo(this.teteaId);
        if (status != 200) {
          this.$message({
            message: msg,
            type: "error",
          });
          setTimeout(() => {
            this.$router.go(-1);
          }, 1000);
        }
        this.searchData=[{'id':this.teteaId}];
        this.sccou_cha_id =  resdata.sccou_cha_id;
        this.formData = resdata;
        this.sccou_id = resdata.sccou_id;
      }else if(this.$route.params.sccou_cha_id){
        //没有课程id 去创建
        const {
          data: resdata,
          status: status,
          msg: msg,
        } = await addTeaTeaching({sccou_cha_id:this.$route.params.sccou_cha_id});
        this.searchData=[{'id':resdata.tetea_id}];
        this.sccou_cha_id =  resdata.sccou_cha_id;
        this.teteaId = resdata.tetea_id;
        this.sccou_id = resdata.sccou_id;
        this.formData = resdata;
      }
      if (this.$route.query.teteaId) {
        this.formData.tetea_id = 0;
      }

    },
    /** 上传失败 */
    handleUploadError(err, file) {
      console.log("123")
      this.$message.warning("上传失败");
    },
    /** 获取页面数据 */
    initData() {
      //   let { grade, sysub, chaper, joint } = this.searchData;
      //   this.searchTitle = `${grade.name}${sysub.name}${
      //     (chaper && `：${chaper.name}`) || ""
      //   }${(joint && `&gt;${joint.name}`) || ""}`;
      //   this.initWeek(); //切换日期


      this.getCourseware();
      this.getKnowledge();
      this.getPractise();
      this.getHomework();
      //   this.changePreparesFrom(this.preparesFrom);
    },
    /**
     * 筛选
     * ---- 获取备课关联id
     */
    async getPrepareId() {
      let { chaper, joint } = this.searchData;
      if (!chaper) return this.$message.warning("请选择需要备课的章！");
      if (!joint) return this.$message.warning("请选择需要备课的节！");
      let { data } = await teach.$getPrepareId(
          (joint && joint.id) || chaper.id
      );
      this.sccou_cha_id = (joint && joint.id) || chaper.id;
      this.teteaId = data.tetea_id;
      this.initData();
      this.hideDialog("search");
    },
    /**
     * 筛选
     * ---- 获取章节数据
     * @param {int} type 数据类型 1：章   2：节
     */
    async getChapters(type) {
      let { grade, sysub, chaper } = this.searchData;
      if (!grade || !sysub) return;
      delete this.searchData.joint; // 清除选中的节id
      if (this.$isEmpty(chaper) && type == 2) return;
      let params = {
        chapter_word: (type == 1 && this.chapterKey) || this.jointKey,
        grade: grade.id,
        sysub_id: sysub.id,
      };
      /* 若选中章触发，传递章id获取节数据 */
      if (chaper && type != 1) params.sccou_cha_pid = chaper.id;
      let { data } = await $getChapters(params);
      /* 章 */
      if (type == 1) {
        this.chapterData = data[0];
        /* 章数据更新，清除选中章及节数据 */
        delete this.searchData.chaper;
        this.jointData = [];
        this.jointKey = "";
      } else {
        /* 节 */
        this.jointData = data[0];
      }
      this.$forceUpdate();
    },
    /**
     * 筛选
     * ---- 筛选节点选中
     * @param {string} el 筛选数据key
     * @param {number} val 筛选数据value
     */
    chooseSearchKey(el, id, name) {
      if (this.searchData[el] && this.searchData[el].id == id) return;
      this.searchData[el] = { id, name };
      /* 根据选中数据类型，清空对应子级数据 */
      switch (el) {
        case "grade":
          delete this.searchData.sysub;
          delete this.searchData.chaper;
          delete this.searchData.joint;
          this.chapterData = [];
          this.jointData = [];
          this.chapterKey = "";
          this.jointKey = "";
          break;
        case "sysub":
          delete this.searchData.chaper;
          delete this.searchData.joint;
          this.chapterData = [];
          this.jointData = [];
          this.chapterKey = "";
          this.jointKey = "";
          break;
        case "chaper":
          delete this.searchData.joint;
          this.jointData = [];
          this.jointKey = "";
          break;
        default:
          break;
      }
      /* 选中科目/章时，获取章节数据 */
      if (this.searchData.grade && this.searchData.sysub && el != "joint") {
        let type = (el == "chaper" && 2) || 1;
        this.getChapters(type);
      }
      this.$forceUpdate();
    },
    /**
     * 选择上课时间
     * ---- 获取每周教师备课课程表
     */
    async getTeacherPrepare() {
      let time = formatTimeStamp(
          `${this.currentYear}/${this.currentMonth}/${this.currentDay}`
      );
      let { data } = await teach.$getTeacherPrepare(time);
      data = data && data.length && data[0];
      this.teacherLessons = data;
      this.$forceUpdate();
    },
    /**
     * 选择上课时间
     * ---- 清除占课内容
     * @param {number} id 课程id
     */
    clearTeachTime(id) {
      this.$msgbox({
        title: "提示",
        message: "确认清除当前课程内容？",
        type: "info",
        showClose: false,
        showCancelButton: true,
        cancelButtonClass: "el-button--custom_info",
        confirmButtonClass: "el-button--custom_primary",
        confirmButtonText: "确定",
        cancelButtonText: "取消",
      })
          .then(async () => {
            let res = await teach.$clearTeachTime(id);
            if (res) {
              this.$message({
                type: "success",
                duration: 1500,
                message: "当前课程内容已清除！",
              });
              this.getTeacherPrepare();
            }
          })
          .catch(() => {
            this.$message({
              type: "info",
              duration: 1500,
              message: "操作已取消",
            });
          });
    },
    /**
     * 选择上课时间
     * ---- 打开占课弹出框
     * @param {string} key 指定的popover组件
     */
    showPopover(key) {
      this.changePopover = true;
      this.currentPopover = key;
      this.lessonForm = {};
      this.$forceUpdate();
    },
    /**
     * 选择上课时间
     * ---- 关闭占课弹出框
     */
    hidePopover() {
      this.$refs[this.currentPopover] &&
      this.$refs[this.currentPopover][0].doClose();
      if (!this.changePopover) this.currentPopover = "";
      this.lessonForm = {};
      this.$forceUpdate();
    },
    /**
     * 选择上课时间
     * ---- 课程表内容选中
     * @param {object} data 课程数据
     * @param {string} time 课程时间
     */
    chooseTeachTime(data, time, status) {
      if (status != -1) return;
      this.teachTime = { ...data, time };
      this.lessonForm.grade_class = `${data.data.sccla_sch_grade},${data.data.sccla_id}`;
      this.setTeachTime(data.data, data.date);
      this.hideDialog("time");
    },
    /**
     * 选择上课时间
     * ---- 设置上课时间（占课）
     * @param {number} sccla_sch_tim_id 日期时间段id
     * @param {date} date 选择占课日期
     */
    async setTeachTime({ sccla_sch_tim_id }, date) {
      this.popoverLoad = true;
      let { grade_class } = this.lessonForm;
      grade_class = grade_class.split(",");
      if (typeof date == "number") date = date * 1000;
      date = formatTime(date, "YYYY/MM/DD");
      let params = {
        sccla_sch_tim_id,
        tetea_id: this.teteaId,
        tecla_grade: grade_class[0],
        sccla_id: grade_class[1],
        date: (typeof date == "string" && formatTimeStamp(date)) || date,
      };
      let res = await teach.$setTeachTime(params);
      this.popoverLoad = false;
      if (res && this.timeDialog) {
        this.hidePopover();
        this.$message.success("课程设置成功");
        this.getTeacherPrepare();
      }
    },
    /**
     * 选择上课时间
     * ---- 周数据格式化
     * @param {Date} time 当前时间
     * @param {String} type 切换类型
     */
    initWeek(time, type) {
      let date = (time && new Date(time)) || new Date();
      let currentDay = date.getDate(); // 今日日期 几号
      let currentYear = date.getFullYear(); // 当前年份
      let currentMonth = date.getMonth() + 1; // 当前月份
      let currentWeek = (date.getDay() == 0 && 7) || date.getDay(); // 星期几 [1,2,3...6,0]
      /* 若切换时currentWeek不是星期一，重置今日日期与星期 */
      if (currentWeek > 1 && time && type == "month") {
        currentDay = currentDay + (7 - currentWeek) + 1;
        currentWeek = 1;
      }
      let str = formatTime(`${currentYear}-${currentMonth}-${currentDay}`); // 今日日期 年-月-日
      let weekDays = [];
      /* 今天是周日，放在第一行第7个位置，前面6个 这里默认显示一周，如果需要显示一个月，则第二个循环为 i<= 35- currentWeek */
      for (let i = currentWeek - 1; i >= 0; i -= 1) {
        let d = new Date(str.replace(/\-/g, "/"));
        d.setDate(d.getDate() - i);
        weekDays.push(d);
      }
      for (let i = 1; i <= 7 - currentWeek; i += 1) {
        let d = new Date(str.replace(/\-/g, "/"));
        d.setDate(d.getDate() + i);
        weekDays.push(d);
      }
      this.currentDay = currentDay;
      this.currentYear = currentYear;
      this.currentMonth = currentMonth;
      this.currentWeek = currentWeek;
      this.weekDays = weekDays;
      this.getTeacherPrepare();
    },
    /**
     * 选择上课时间
     * ---- 周切换
     * @param {Number} type 切换方向
     */
    handleChangeWeek(type) {
      let date;
      if (type === 1) {
        date = this.weekDays[6];
        date.setDate(date.getDate() + 7);
      } else {
        date = this.weekDays[0];
        date.setDate(date.getDate() - 7);
      }
      this.initWeek(date);
    },
    /**
     * 选择上课时间
     * ---- 月份切换
     * @param {Number} year 当前年份
     * @param {Number} month 当前月份
     * @param {Number} type 切换方向
     */
    handleChangeMonth(type) {
      let year = this.currentYear;
      let month = this.currentMonth;
      let date = new Date(formatTime(`${year}-${month}-1`));
      if (type == 1) {
        date.setDate(35);
      } else {
        date.setDate(0);
      }
      this.initWeek(
          formatTime(`${date.getFullYear()}-${date.getMonth() + 1}-1`),
          "month"
      );
    },
    /**
     * 课件
     * ---- 获取课件资源
     */
    async getCourseware() {
      let {
        data: resFile
      } = await getGroupData("COURSEWARE");
      this.upFileOption = resFile;
      let { data } = await teach.$getPrepareCourseware(this.teteaId);
      this.courseware = data;
      this.$forceUpdate();
    },
    /**
     * 课件
     * ---- 新增备课课件
     */
    addCourseware() {
      this.$refs.uploadForm.validate(async (valid) => {
        if (valid) {
          this.coursewareLoad = true;
          let params = { ...this.uploadForm, tetea_id: this.teteaId };
          this.hideDialog("upload");
          let res = await teach.$uploadPrepareCourseware(params);
          this.coursewareLoad = false;
          if (res) this.getCourseware();
        }
      });
    },
    /** 删除备课课件 */
    deleteCourseware() {
      this.$msgbox({
        title: "删除",
        message: "确认删除此备课课件？",
        type: "warning",
        showClose: false,
        showCancelButton: true,
        cancelButtonClass: "el-button--custom_info",
        confirmButtonClass: "el-button--custom_primary",
        confirmButtonText: "确定",
        cancelButtonText: "取消",
      })
          .then(async () => {
            this.allloading=true
            let res = await teach.$delPrepareCourseware(
                this.courseware[0].tetea_cou_id
            );
            if (res) {
              this.$message({
                type: "success",
                duration: 1500,
                message: "课件已清除！",
              });
              this.getCourseware();
            }
            this.allloading=false
          })
          .catch(() => {
            this.allloading=false
            this.$message({
              type: "info",
              duration: 1500,
              message: "操作已取消",
            });
          });
    },
    /**
     * 课件
     * ---- 文件上传成功
     * @param {object} data 响应结果
     * @param {string} name 上传原文件名
     */
    uploadSuccess({ data, msg }, { name }) {
      if (this.$isEmpty(data)) return this.$message.error(msg);
      this.uploadForm.tetea_cou_url = data.address;
      this.uploadForm.tetea_cou_type = data.file_type;
      let courseware = { name, type: data.file_type };
      this.coursewareList = [courseware];
      this.$refs.uploadForm.validateField("tetea_cou_url");
    },

    /**
     * 课件
     * ---- 移出上传课件资源
     */
    removeCoursewareFile() {
      delete this.uploadForm.tetea_cou_url;
      delete this.uploadForm.tetea_cou_type;
      this.coursewareList = [];
    },
    /** 下载知识点模板 */
    async downloadTemplate() {
      let { data } = await teach.$downloadTemplate();
      this.$fileDownload(data.full_file_url);
    },

    /** 导入知识点模板 */
    async uploadTimetable() {
      if (this.$isEmpty(this.knowledgeImportForm.url))
        return this.$message.warning("请上传需要导入的文件");
      this.btnload = true;
      let params = {
        tetea_id: this.teteaId,
        inportfile: this.knowledgeImportForm.url,
      };
      this.fileList = [];
      let res = await teach.$importKnowledge(params);
      this.btnload = false;
      if (res) {
        this.hideDialog("knowledgeImport");
        this.$refs.importUpload.clearFiles()
        this.getKnowledge();
      }
    },
    /** 删除知识点上传文件 */
    deleteUploadFile(index) {
      this.fileList.splice(index, 1);
    },
    /** 删除练习题上传文件 */
    deletepractiseUploadFile(index) {
      this.practiseImportfileList.splice(index, 1);
    },
    /**
     * 知识点
     * ---- 获取备课知识点
     */
    async getKnowledge() {
      let { data } = await teach.$getPrepareKnowledge(this.teteaId);
      this.prepareKnowledge = data;
      this.$forceUpdate();
    },
    /**
     * 知识点
     * ---- 新增备课知识点
     */
    addKnowledge() {
      this.$refs.knowledgeForm.validate(async (valid) => {
        if (valid) {
          this.knowledgeLoad = true;
          let params = { ...this.knowledgeForm };
          if (params.tetea_kno_id) return this.editKnowledge(params);
          params.tetea_id = this.teteaId;
          let res = await teach.$addPrepareKnowledge(params);
          this.knowledgeLoad = false;
          if (res) {
            this.hideDialog("knowledge");
            this.getKnowledge();
          }
        }
      });
    },
    /**
     * 知识点
     * ---- 编辑备课知识点
     * @param {object} params 表单数据
     */
    async editKnowledge(params) {
      let res = await teach.$editPrepareKnowledge(params);
      this.knowledgeLoad = false;
      if (res) {
        this.hideDialog("knowledge");
        this.getKnowledge();
        this.$message({
          type: "success",
          duration: 1500,
          message: "知识点编辑保存成功",
        });
      }
    },
    /**
     * 课件
     * ---- 文件上传成功
     * @param {object} data 响应结果
     * @param {string} name 上传原文件名
     */
    uploadknowledgeImportSuccess({ data, msg }, { name }) {
      if (this.$isEmpty(data)) return this.$message.error(msg);
      this.knowledgeImportForm.url = data.address;
      let courseware = { name, type: data.file_type };
      this.fileList = [courseware];
    },

    /**
     * 知识点
     * ---- 删除备课知识点
     * @param {number} id 知识点id
     */
    delKnowledge(id) {
      this.$msgbox({
        title: "删除",
        message: "确认删除此条知识点？",
        type: "warning",
        showClose: false,
        showCancelButton: true,
        cancelButtonClass: "el-button--custom_info",
        confirmButtonClass: "el-button--custom_primary",
        confirmButtonText: "确定",
        cancelButtonText: "取消",
      })
          .then(async () => {
            this.allloading=true
            let res = await teach.$delPrepareKnowledge({id:id});
            if (res.status == 200) {
              this.$message({
                type: "success",
                duration: 1500,
                message: "知识点已删除！",
              });
              this.getKnowledge();
            }
            this.allloading=false
          })
          .catch(() => {
            this.allloading=false
            this.$message({
              type: "info",
              duration: 1500,
              message: "操作已取消",
            });
          });
    },
    /**
     * 课堂练习
     * ---- 获取课堂练习数据
     */
    async getPractise() {
      let { data } = await teach.$getPreparePractise(this.teteaId);
      this.preparePractise = data;
      this.$forceUpdate();
    },
    /** 下载练习题导入模板 */
    async downloadPractiseTemplate(type) {
      let { data } = await teach.$downloadPractiseTemplate({type:type});
      this.$fileDownload(data.full_file_url);
    },
    /**
     * 练习题批量上传
     * ---- 文件上传成功
     * @param {object} data 响应结果
     * @param {string} name 上传原文件名
     */
    uploadpractiseImportSuccess({ data, msg }, { name }) {
      if (this.$isEmpty(data)) return this.$message.error(msg);
      this.practiseImportForm.url = data.address;
      let courseware = { name, type: data.file_type };
      this.practiseImportfileList = [courseware];
      this.$forceUpdate();
    },
    /** 导入练习题模板 */
    async uploadPractisetable() {
      if (this.$isEmpty(this.practiseImportForm.url))
        return this.$message.warning("请上传需要导入的文件");
      this.btnload = true;
      let params = {
        tetea_id: this.teteaId,
        inportfile: this.practiseImportForm.url,
      };
      this.fileList = [];
      let res = await teach.$importTeachingPractise(params);
      this.btnload = false;
      if (res) {
        this.$refs.importUpload2.clearFiles();
        let { data } = res;
        this.hideDialog("practiseImport");
        if(res.status!=200){return false;}
        if(data.isword){
          if (data.succNum > 0) {
            this.questionDoc = data.questions
            this.succNum = data.succNum
            this.showQuestionDoc = true
          }
        }else{
          if (data.errorNum) {
            this.$message.warning(`共导入${data.allCount}道题，导入失败${data.errorNum}道`);
          } else {
            this.$message.success(`成功导入${data.allCount}道题`)
          }
          this.getPractise();
          if (data.errorNum && data.errorFileUrl) this.$fileDownload(data.errorFileUrl)
        }
      }
    },
    /**
     * 课堂练习
     * ---- 获取课堂练习详情
     * @param {number} id 课堂练习id
     * @param {string} type 弹窗类型
     */
    async getPractiseDetail(id, type) {
      let { data } = await teach.$preparePractiseDetail(id);
      // let answer = [];
      // if (type == "practise") {
      //   let form = {},
      //     formKey = [
      //       "tetea_pra_id",
      //       "syque_typ_id",
      //       "tetea_kno_id",
      //       "tetea_pra_title",
      //       "tetea_pra_analysis",
      //       "tetea_pra_title_image",
      //       "item",
      //     ];
      //   data.item.map(
      //     (i, index) => i.tetea_pra_ite_isanswer == 10 && answer.push(index)
      //   );
      //   formKey.map((item) => (form[item] = data[item]));
      //   if (data.syque_typ_id != 2) answer = answer[0];
      //   this.practiseForm = {
      //     ...form,
      //     answer,
      //   };
      // } else {
      //   data.item.map(
      //     (i) =>
      //       i.tetea_pra_ite_isanswer == 10 && answer.push(i.tetea_pra_ite_code)
      //   );
      //   this.practiseDetail = {
      //     ...data,
      //     answer: answer.join(","),
      //   };
      // }

      let option_arr = [];
      data.option_arr.forEach((que) => {
        let que_item = {
          answer: [],
          syque_typ_id: que.syque_typ_id,
          question_item: [...que.question_item],
        };
        if (data.tetea_pra_ismakeup == 10) {
          if (que.tetea_pra_title)
            que_item.tetea_children_title = que.tetea_pra_title;
          if (que.tetea_pra_title_image)
            que_item.tetea_children_image = que.tetea_pra_title_image;
        }
        if (que.tetea_kno_id) {
          que_item.tetea_kno_id = que.tetea_kno_id;
        }
        if (que.tetea_pra_analysis)
          que_item.tetea_pra_analysis = que.tetea_pra_analysis;
        que.sure_answer.map((item) => {
          que_item.answer.push(enChartToNum(item));
        });

        que_item.answer =
            (que_item.answer.length > 1 && que_item.answer) || que_item.answer[0];
        option_arr.push(que_item);
      });

      let formData = {
        tetea_pra_id: data.tetea_pra_id,
        tetea_pra_title: data.tetea_pra_title,
        tetea_pra_ismakeup: data.tetea_pra_ismakeup,
        option_arr: [...option_arr],
      };
      if (data.tetea_pra_title_image)
        formData.tetea_pra_title_image = data.tetea_pra_title_image;

      if (type == "practise") {
        this.practiseForm = { ...formData };
      } else if (type == "practiseDetail") {
        this.practiseDetail = { ...data };
      }

      this.$forceUpdate();
    },

    /**
     * 课堂练习
     * ---- 新增课堂练习
     */
    addPractise() {
      this.$refs.practiseForm.validate(async (valid) => {
        if (valid) {
          this.practiseLoad = true;

          let option_arr = this.$_.cloneDeep(this.practiseForm.option_arr);
          let choiceQues = option_arr.filter((que) => {
            if (this.isChoice(que.syque_typ_id)) return que;
          });
          let answerCount = [];
          choiceQues.map((que) => {
            if (typeof que.answer == "number" || !this.$isEmpty(que.answer))
              answerCount.push(que.answer);
          });
          if (answerCount.length < choiceQues.length) {
            this.practiseLoad = false;
            return this.$message.warning("请设定题目正确答案");
          }

          /* 遍历所有题目,设置章节id */
          option_arr.map((que) => {
            let { question_item } = que;
            /* 选择题,将正确答案赋值到选项 */
            if (this.isChoice(que.syque_typ_id)) {
              question_item.map((item) => (item.tetea_pra_ite_isanswer = 20));
              if (typeof que.answer == "number") {
                question_item[que.answer].tetea_pra_ite_isanswer = 10;
              } else {
                que.answer.map((a) => {
                  question_item[a].tetea_pra_ite_isanswer = 10;
                });
              }
            }
            if (que.chapter && !this.$isEmpty(que.chapter)) {
              que.tetea_kno_id = que.chapter[que.tetea_kno_id.length - 1];
            }
            question_item.map((opt, opt_index) => {
              opt.teque_ite_code = changeEnChart(opt_index);
            });
            delete que.answer;
            delete que.chapter;
            que = { ...que, question_item: [...question_item] };
          });
          let params = {
            ...this.practiseForm,
            sccou_id: this.qbId,
            option_arr: [...option_arr],
          };
          params.tetea_id = this.teteaId; // 传入备课id

          if (params.teque_id) return this.editQuestion(params); // 题目id存在，执行数据编辑保存
          // if (params.tetea_pra_id) return this.editPractise(params); // 课堂练习id存在，执行数据编辑保存
          let res = await teach.$addPreparePractise(params);
          this.practiseLoad = false;
          if (res) {
            this.hideDialog("practise"); // 关闭表单弹窗
            this.getPractise();
          }
        }
      });
    },

    /**
     * 选中题目题型
     * @param {number} val 题型选中值
     * @param {number} que_index 题目数组下标
     */
    chooseQuesType(val, que_index) {
      let option = {
        tetea_pra_ite_title: "",
        tetea_pra_ite_isanswer: 20,
        tetea_pra_ite_image: "",
      };
      let question_item = [];
      let { option_arr } = this.practiseForm;
      if (this.isChoice(val)) {
        let loopTime = (val == 5 && 2) || 4;
        for (let i = 0; i < loopTime; i++) {
          question_item.push({ ...option });
        }
      } else {
        question_item = [
          {
            tetea_pra_ite_title: "",
            tetea_pra_ite_isanswer: 10,
            tetea_pra_ite_image: "",
          },
        ];
      }
      option_arr[que_index] = {
        ...option_arr[que_index],
        answer: (val == 2 && []) || "",
        question_item: [...question_item],
      };
      this.practiseForm = { ...this.practiseForm, option_arr: [...option_arr] };
      this.$forceUpdate();
    },
    /** 添加组合题小题 */
    appendQues() {
      let { option_arr } = this.practiseForm;
      option_arr.push({});
      this.practiseForm = { ...this.practiseForm, option_arr: [...option_arr] };
      this.$forceUpdate();
    },
    /**
     * 移除组合题小题
     * @param {number} que_index 题目数组下标
     */
    removeQues(que_index) {
      let { option_arr } = this.practiseForm;
      this.$_.pullAt(option_arr, que_index);
      this.practiseForm = { ...this.practiseForm, option_arr: [...option_arr] };
      this.$forceUpdate();
    },
    /**
     * 课堂练习
     * ---- 编辑课堂练习
     * @param {object} params 表单数据
     */
    async editPractise(params) {
      let res = await teach.$editPreparePractise(params);
      this.practiseLoad = false;
      if (res) {
        this.hideDialog("practise"); // 关闭表单弹窗
        this.getPractise();
        this.$message({
          type: "success",
          duration: 1500,
          message: "课堂练习编辑保存成功",
        });
      }
    },
    /**
     * 课堂练习
     * ---- 删除课堂练习
     * @param {number} id 课堂练习id
     */
    delPractise(id) {
      this.$msgbox({
        title: "删除",
        message: "确认删除此条课堂练习题吗？",
        type: "warning",
        showClose: false,
        showCancelButton: true,
        cancelButtonClass: "el-button--custom_info",
        confirmButtonClass: "el-button--custom_primary",
        confirmButtonText: "确定",
        cancelButtonText: "取消",
      })
          .then(async () => {
            this.allloading=true
            let res = await teach.$delPreparePractise(id);
            if (res) {
              this.$message({
                type: "success",
                duration: 1500,
                message: "课堂练习题已删除！",
              });
              this.getPractise();
            }
            this.allloading=false
          })
          .catch(() => {
            this.allloading=false
            this.$message({
              type: "info",
              duration: 1500,
              message: "操作已取消",
            });
          });
    },

    /**
     * 课堂练习
     * ---- 图片上传成功
     * @param {object} data 响应数据
     * @param {string} el 图片对应字段变量
     */
    // uploadImgSuccess({ data, msg }, el) {
    //   if (this.$isEmpty(data)) return this.$message.error(msg);
    //   el = el.split(".");
    //   if (el.length == 1) this[el[0]] = data.address;
    //   if (el.length == 2) this[el[0]][el[1]] = data.address;
    //   if (el.length == 3) this[el[0]][el[1]][el[2]] = data.address;
    //   if (el.length == 4) this[el[0]][el[1]][el[2]][el[3]] = data.address;
    //   this.$forceUpdate();
    // },
    /**
     * 课堂练习
     * ---- 添加题目选项
     */
    appendQueOption_pra(que_index) {
      let { option_arr } = this.practiseForm;
      let { question_item } = option_arr[que_index];
      const option = {
        tetea_pra_ite_title: "",
        tetea_pra_ite_isanswer: 20,
        tetea_pra_ite_image: "",
      };
      question_item.push(option);
      option_arr[que_index] = {
        ...option_arr[que_index],
        question_item: [...question_item],
      };
      this.practiseForm = { ...this.practiseForm, option_arr: [...option_arr] };
    },
    /** 选中题目是否为组合题 */
    checkIsCompose(val) {
      let { tetea_pra_title, tetea_pra_image } = this.practiseForm;
      let form = { tetea_pra_ismakeup: val, option_arr: [{}], tetea_pra_title };
      if (tetea_pra_image) form.tetea_pra_image = tetea_pra_image;
      this.practiseForm = { ...form };
      this.$refs.practiseForm.clearValidate();
      this.$forceUpdate();
    },
    /**
     * 移除题目选项
     * @param {number} que_index 题目数组下标
     * @param {number} opt_index 题目选项数组下标
     */
    removeQueOption_pra(que_index, opt_index) {
      let { option_arr } = this.practiseForm;
      let { question_item, answer } = option_arr[que_index];
      if (answer + "") {
        if (typeof answer == "number") {
          if (answer == opt_index) answer = "";
          if (answer > opt_index) answer = (answer - 1 < 0 && "") || answer - 1;
        } else {
          if (answer.indexOf(opt_index) != -1) {
            let a_index = answer.indexOf(opt_index);
            this.$_.pullAt(answer, a_index);
          }
          let newAnswer = [];
          answer.map((item) => {
            if (item > opt_index) newAnswer.push(item - 1);
            else newAnswer.push(item);
          });
          answer = [...newAnswer];
        }
      }
      this.$_.pullAt(question_item, opt_index);
      option_arr[que_index] = {
        ...option_arr[que_index],
        question_item: [...question_item],
        answer,
      };
      this.practiseForm = { ...this.practiseForm, option_arr: [...option_arr] };
      this.$forceUpdate();
    },

    /**
     * 课堂练习
     * ---- 切换题目类型
     * @param {number} val 题目类型值
     */
    checkQuestionType(val) {
      const option = {
        tetea_pra_ite_title: "",
        tetea_pra_ite_isanswer: 20,
        tetea_pra_ite_image: "",
      };
      let answer = (val == 2 && []) || "";
      let item = [];
      let loopTime = (val == 5 && 2) || 4;
      for (let i = 0; i < loopTime; i++) {
        item.push({ ...option });
      }

      this.practiseForm = {
        ...this.practiseForm,
        answer,
        item: [...item],
      };
    },
    /**
     * 课堂练习
     * ---- 图片上传成功
     * @param {object} data 响应数据
     * @param {string} el 图片对应字段变量
     */
    uploadImgSuccess({ data, msg }, el) {
      if (this.$isEmpty(data)) return this.$message.error(msg);
      el = el.split(".");
      if (el.length == 1) this[el[0]] = data.address;
      if (el.length == 2) this[el[0]][el[1]] = data.address;
      if (el.length == 3) this[el[0]][el[1]][el[2]] = data.address;
      if (el.length == 4) this[el[0]][el[1]][el[2]][el[3]] = data.address;
      if (el.length == 5)
        this[el[0]][el[1]][el[2]][el[3]][el[4]] = data.address;
      if (el.length == 6)
        this[el[0]][el[1]][el[2]][el[3]][el[4]][el[5]] = data.address;
      this.$forceUpdate();
    },
    /**
     * 课堂练习
     * ---- 添加题目选项
     */
    appendQueOption() {
      const option = {
        tetea_pra_ite_title: "",
        tetea_pra_ite_isanswer: 20,
        tetea_pra_ite_image: "",
      };
      this.practiseForm = {
        ...this.practiseForm,
        item: [...this.practiseForm.item, { ...option }],
      };
      this.$forceUpdate();
    },
    /**
     * 课堂练习
     * ---- 移除题目选项
     * @param {number} index 选项下标
     */
    removeQueOption(index) {
      let { item: options, answer } = this.practiseForm;
      if (answer + "") {
        if (typeof answer == "number") {
          if (answer == index) answer = "";
          else answer = (answer - 1 < 0 && "") || answer - 1;
        } else {
          if (answer.indexOf(index) != -1) {
            let a_index = answer.indexOf(index);
            this.$_.pullAt(answer, a_index);
          }
          let newAnswer = [];
          answer.map((item) => {
            if (item > index) newAnswer.push(item - 1);
            else newAnswer.push(item);
          });
          answer = [...newAnswer];
        }
      }
      this.$_.pullAt(options, index);
      this.$refs.practiseForm.clearValidate(
          `item.${index}.tetea_pra_ite_title`
      );
      this.practiseForm = {
        ...this.practiseForm,
        item: [...options],
        answer,
      };
      this.$forceUpdate();
    },
    /**
     * 作业
     * ---- 获取备课作业信息
     */
    async getHomework() {
      let { data } = await teach.$getPrepareHomework(this.teteaId);
      this.prepareHomework = data["prepareHomework"] || {};
      this.prepareSchoolHomework = data["prepareSchoolHomework"] || {};
      this.$forceUpdate();
    },
    /**
     * 作业
     * ---- 获取作业详情
     * @param {string} type 弹窗类型
     */
    async getHomeworkDetail(type) {
      let { data } = await teach.$prepareHomeworkDetail(this.hom_id);
      if (type == "homework") {
        let form = {},
            formKey = [
              "tetea_hom_id",
              "tetea_hom_title",
              "tetea_hom_remark",
              "tetea_hom_type",
            ];
        formKey.map((item) => (form[item] = data[item]));
        this.homeworkForm = {
          ...form,
        };

        this.homeworkQues = [...data.question];
      } else {
        this.homeworkDetail = { ...data };
      }
    },
    /**
     * 作业
     * ---- 获取可选题目列表
     */
    async getQues() {
      if(!this.sccou_id){
        this.$message.warning("章节信息错误");
      }
      let params = {
        syque_typ_id: this.homeworkForm.syque_typ_id,
        query_word: this.selectQuesKey,
        pageindex: this.selectQuesPage.index,
        sccou_id: this.sccou_id,
      };
      delete this.homeworkForm.que_id;
      let { data: res } = await teach.$getQuesList(3, params);
      this.selectQues = [...this.selectQues, ...res.data];
      this.selectQuesPage.total = res.allcount;
      console.log(this.selectQues,"1111111111111")
      this.$forceUpdate();
    },
    /**
     * 作业
     * ---- 筛选可选题目列表
     */
    searchQues() {
      this.selectQues = [];
      this.selectQuesKey = "";
      this.selectQuesPage.index = 1;
      delete this.homeworkForm.scque_id;
      this.getQues();
    },
    /**
     * 作业
     * ---- 根据关键字筛选可选题目
     * @param {string} query 可选题目关键字
     */
    filterQues(query) {
      this.selectQuesKey = query;
      this.selectQues = [];
      this.selectQuesPage.index = 1;
      this.getQues();
    },
    /**
     * 作业
     * ---- 更多可选题目数据加载
     */
    loadMoreQues() {
      if (this.selectQues.length == this.selectQuesPage.total) return;
      this.selectQuesPage.index++;
      this.getQues();
    },
    /**
     * 作业
     * ---- 添加选中题目
     */
    appendHomeworkQue() {
      if (!this.homeworkForm.syque_typ_id || !this.homeworkForm.scque_id) return;
      let { scque_id } = this.homeworkForm;
      delete this.homeworkForm.syque_typ_id;
      delete this.homeworkForm.scque_id;
      if (!this.$_.find(this.homeworkQues, ["scque_id", scque_id])) {
        let que = this.$_.find(this.selectQues, ["scque_id", scque_id]);
        this.homeworkQues.push(que);
      } else {
        this.$message.warning("选中项已存在作业题目列表中");
      }
      this.selectQues = [];
      this.selectQuesKey = "";
      this.selectQuesPage.index = 1;
      this.atPresentQues = {};
      this.$forceUpdate();
    },
    /**
     * 作业
     * ---- 移除选中题目
     * @param {number} index 题目下标
     */
    removeHomeworkQue(index) {
      this.$_.pullAt(this.homeworkQues, index);
      this.$forceUpdate();
    },
    /**
     * 作业
     * ---- 调整题目顺序
     * @param {string} type 调整类型
     * @param {number} index 题目下标
     */
    changeSort(type, index) {
      const dot_1 = this.homeworkQues[index];
      let dot_2;
      /* 下移 */
      if (type == "d") {
        dot_2 = this.homeworkQues[index + 1];
        this.homeworkQues[index + 1] = { ...dot_1 };
      }
      /* 上移 */
      if (type == "u") {
        dot_2 = this.homeworkQues[index - 1];
        this.homeworkQues[index - 1] = { ...dot_1 };
      }
      this.homeworkQues[index] = { ...dot_2 };
      this.$forceUpdate();
    },
    /**
     * 作业
     * ---- 添加作业
     */
    addHomework() {
      this.$refs.homeworkForm.validate(async (valid) => {
        if (valid) {
          this.homeworkLoad = true;
          let params = this.$_.cloneDeep(this.homeworkForm);
          params.tetea_id = this.teteaId; // 传入备课id
          params.question = [];
          let homeworkQues = this.$_.cloneDeep(this.homeworkQues);
          homeworkQues.map((item) => {
            let que = {
              que_id: item.scque_id,
              tetea_hom_ite_ismakeup: item.scque_ismakeup,
            };
            params.question.push(que);
          });
          if (params.tetea_hom_id) return this.editHomework(params); // 存在作业id，执行作业编辑
          let res = await teach.$addPrepareHomework(params);
          this.homeworkLoad = false;
          if (res.status==200) {
            this.hideDialog("homework"); // 关闭表单弹窗
            this.getHomework();
          }
        }
      });
    },
    /**
     * 作业
     * ---- 编辑作业
     * @param {object} params 表单数据
     */
    async editHomework(params) {
      let res = await teach.$editPrepareHomework(params);
      this.homeworkLoad = false;
      if (res) {
        this.hideDialog("homework"); // 关闭表单弹窗
        this.getHomework();
        this.$message({
          type: "success",
          duration: 1500,
          message: "作业编辑保存成功",
        });
      }
    },
    /**
     * 作业
     * ---- 删除作业
     */
    delHomework(hom_id) {
      this.$msgbox({
        title: "删除",
        message: "确认删除作业？",
        type: "warning",
        showClose: false,
        showCancelButton: true,
        cancelButtonClass: "el-button--custom_info",
        confirmButtonClass: "el-button--custom_primary",
        confirmButtonText: "确定",
        cancelButtonText: "取消",
      })
          .then(async () => {
            this.allloading=true
            let res = await teach.$delPrepareHomework(hom_id);
            if (res) {
              this.$message({
                type: "success",
                duration: 1500,
                message: "作业已删除！",
              });
              this.getHomework();
            }
            this.allloading=false
          })
          .catch(() => {
            this.allloading=false
            this.$message({
              type: "info",
              duration: 1500,
              message: "操作已取消",
            });
          });
    },
    /**
     * 开启弹窗
     * @param {string} type 弹窗类型
     */
    showDialog(type, ...params) {
      let data = {};
      if (this.previewPrepare) {
        this.hideDialog("knowledgeDetail");
        this.hideDialog("practiseDetail");
        this.hideDialog("homeworkDetail");
      }
      if (this.previewDialog) this.previewDialog = false;
      if (this[`${type}Form`]) {
        this.resetForm(`${type}Form`);
      }
      if (params.length) {
        if (type == "knowledge" || type == "knowledgeDetail") {
          data = {
            tetea_kno_id: params[0].tetea_kno_id,
            tetea_kno_title: params[0].tetea_kno_title,
            tetea_kno_content: params[0].tetea_kno_content,
          };
        }
        if (type == "practise" || type == "practiseDetail") {
          this.getPractiseDetail(params[0], type);
        }
        if (type == "homework" || type == "homeworkDetail") {
          this.hom_id = params;

          this.getHomeworkDetail(type);
        }
      }
      switch (type) {
        case "time":
          this.getTeacherPrepare();
          break;
        case "knowledge":
          this.knowledgeForm = { ...data };
          break;
        case "knowledgeDetail":
          this.knowledgeDetail = { ...data };
        case "knowledgeImport":
          this.knowledgeImportForm.url = "";
          this.knowledgeDetail = { ...data };
        case "practiseImport":
          this.practiseImportForm.url = "";
          this.practiseImportfileList = [];
        case "practise":
          this.practiseForm = {
            ...this.practiseForm,
            item: [{}, {}],
            tetea_pra_ismakeup: 20,
          };

          break;
        default:
          break;
      }
      this[`${type}Dialog`] = true;
    },
    /**
     * 关闭弹窗
     * @param {string} type 弹窗类型
     */
    hideDialog(type) {
      this[`${type}Dialog`] = false;
      if (type == "search" && !this.searchData.chaper) {
        this.searchData = {};
      }
      if (this[type]) {
        this[type] = {};
      }
      if (this[`${type}Form`]) {
        switch (type) {
          case "upload":
            this.coursewareList = [];
            break;
          case "homework":
            this.homeworkQues = [];
            this.selectQues = [];
            break;
          default:
            break;
        }
        this.$nextTick(() => {
          this.resetForm(`${type}Form`);
          this[`${type}Form`] = {};
          if (type == "practise") {
            this.practiseForm = {
              tetea_pra_ismakeup: 20,
              option_arr: [{}],
            };
          }
        });
      }
    },
    /** 重置表单 */
    resetForm(ref) {
      if (this.$refs[ref]) this.$refs[ref].clearValidate();
    },
    // 切换题
    queSelect(e) {
      if (e.id) {
        this.atPresentQues = e.item;
      } else {
        this.atPresentQues = {};
      }
      // this.selectQues.forEach(item=>{
      //   if(item.teque_id==e){
      //     this.atPresentQues=item
      //   }
      // })
    },
    /* ------------------------------ 以下是添加的引用备课数据功能相关方法 ------------------------------ */
    /** 切换备课数据来源 */
    changePreparesFrom(val) {
      this.preparesFrom = val;
      if (!this.sccou_cha_id) return;
      this.preparesPage = {
        ...this.preparesPage,
        pageindex: 1,
      };
      let params = {
        sccou_cha_id: this.sccou_cha_id,
        pageindex: 1,
        pagesize: this.preparesPage.pagesize,
      };
      this.quoteLoad = true;
      if (val == 1) {
        this.getSchoolPrepares(params);
      } else {
        this.getPlatformPrepares(params);
      }
    },
    /** 获取公共备课数据（学校） */
    async getSchoolPrepares(params) {
      let { data: res } = await teach.$getSchoolPrepares(params);
      this.quoteLoad = false;
      this.preparesData = [...res.data];
      this.preparesPage = {
        ...this.preparesPage,
        total: res.total,
      };
      this.$forceUpdate();
    },
    /** 获取公共备课数据（平台） */
    async getPlatformPrepares(params) {
      let { data: res } = await teach.$getPlatformPrepares(params);
      this.quoteLoad = false;
      this.preparesData = [...res.data];
      this.preparesPage = {
        ...this.preparesPage,
        total: res.total,
      };
      this.$forceUpdate();
    },
    /** 获取待引用备课数据详情 */
    async getPreparesDetails(tetea_id) {
      this.previewPrepare = true;
      this.getQuoteCourseware(tetea_id);
      this.getQuoteKnowledge(tetea_id);
      this.getQuotePractise(tetea_id);
      this.getQuoteHomework(tetea_id);
    },
    closePreviewPrepare() {
      this.previewPrepare = false;
    },
    /** 引用备课数据 */
    async quotePrepare(tetea_id) {
      this.quote_doing = true;
      let res = await teach.$quotePrepare2(tetea_id, this.teteaId);
      if (res) {
        this.initData();
        this.quote_doing = false;
        this.$message({
          type: "success",
          duration: 1500,
          message: "备课数据引用成功！",
        });
      } else {
        this.quote_doing = false;
      }
    },
    /** 引用备课数据 - 课件信息 */
    async getQuoteCourseware(tetea_id) {
      this.quoteLoad1 = true;
      let { data } = await teach.$getPrepareCourseware(tetea_id);
      this.quoteLoad1 = false;
      this.quoteCourseware = data;
    },
    /** 引用备课数据 - 知识点 */
    async getQuoteKnowledge(tetea_id) {
      this.quoteLoad2 = true;
      let { data } = await teach.$getPrepareKnowledge(tetea_id);
      this.quoteLoad2 = false;
      this.quoteKnowledge = data;
    },
    /** 引用备课数据 - 练习题 */
    async getQuotePractise(tetea_id) {
      this.quoteLoad3 = true;
      let { data } = await teach.$getPreparePractise(tetea_id);
      this.quoteLoad3 = false;
      this.quotePractise = data;
    },
    /** 引用备课数据 - 作业 */
    async getQuoteHomework(tetea_id) {
      this.quoteLoad4 = true;
      let { data } = await teach.$getPrepareHomework(tetea_id);
      this.quoteLoad4 = false;
      this.quoteHomework = data["prepareHomework"] || {};
      this.quoteSchoolHomework = data["prepareSchoolHomework"] || {};
    },
    /** 翻页 */
    flippingPage(val) {
      this.preparesPage.pageindex = val;
      let params = {
        sccou_cha_id: this.sccou_cha_id,
        pageindex: val,
        pagesize: this.preparesPage.pagesize,
      };
      if (this.preparesFrom == 1) {
        this.getSchoolPrepares(params);
      } else {
        this.getPlatformPrepares(params);
      }
    },
    //添加小题答案
    addDocQuestion(item, index) {
      if (item.question_item.length < 20) {
        let letter = ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T"]
        item.question_item.push({ tetea_pra_ite_code: letter[item.question_item.length], tetea_pra_ite_isanswer: 20, tetea_pra_ite_title: "" })
        this.questionDoc[index] = item
      } else {
        this.$message({
          type: "info",
          duration: 1500,
          message: "答案上限",
        });
      }
    },
    //删除答案
    deleteDocQuestionItem(item, index2) {
      if (item.question_item.length > 1) {
        let letter = ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T"]
        if (item.syque_typ_id == 1 || item.syque_typ_id == 5) {
          if (item.answer == item.question_item[index2].tetea_pra_ite_code) {
            item.answer = ""
          }
        }
        item.question_item.splice(index2, 1)
        item.question_item.map((item2, index) => {
          item2.tetea_pra_ite_code = letter[index]
        })
      } else {
        this.$message({
          type: "info",
          duration: 1500,
          message: "至少保留一个答案",
        });
      }
    },
    //删除大题
    deleteQuestionDocItem(index) {
      if (this.questionDoc.length > 1) {
        this.questionDoc.splice(index, 1)
      } else {
        this.$message({
          type: "info",
          duration: 1500,
          message: "至少保留一个大题",
        });
      }
    },
    // 提交表单
    async questionDocsubmit() {
      let pass = true
      let error = ""
      let newsDoc = JSON.parse(JSON.stringify(this.questionDoc))
      newsDoc.map((item, index) => {
        if (item.sccou_cha_id) {
          item.sccou_cha_id = item.sccou_cha_id[item.sccou_cha_id.length - 1]
        }
        if (pass && item.tetea_pra_title.length < 1) {
          error = "请填写第" + (index + 1) + "题题目"
          pass = false
        }
        if (item.syque_typ_id == 1) {
          //遍历选择题里面的小题
          item.question_item.map(item2 => {
            if (item.answer == item2.tetea_pra_ite_code) {
              item2.tetea_pra_ite_isanswer = 10
            } else {
              item2.tetea_pra_ite_isanswer = 20
            }
          })
          let ispass = item.answer.length > 0
          let isvalpass = item.question_item.some(function (item2) {
            return item2.tetea_pra_ite_title == ""
          })
          if (pass && !ispass) {
            error = "请勾选第" + (index + 1) + "题正确答案"
            pass = false
          }
          if (pass && isvalpass) {
            error = "请填写第" + (index + 1) + "题正确答案"
            pass = false
          }
        }
        if (item.syque_typ_id == 5 || item.syque_typ_id == 2) {
          let ispass = item.question_item.some(function (item2) {
            return item2.tetea_pra_ite_isanswer == 10
          })
          let isvalpass = item.question_item.some(function (item2) {

            return item2.tetea_pra_ite_title == ""
          })
          if (pass && !ispass) {
            error = "请勾选第" + (index + 1) + "题正确答案"
            pass = false
          }
          if (pass && isvalpass) {
            error = "请填写第" + (index + 1) + "题正确答案"
            pass = false
          }
        }
        if (item.syque_typ_id == 3 || item.syque_typ_id == 4 || item.syque_typ_id == 7 || item.syque_typ_id == 8) {
          let tetea_pra_ite_title = []
          item.question_item.map((item2, index2) => {
            //填空题设置默认值
            if (item2.tetea_pra_ite_title.length > 0) {
              tetea_pra_ite_title.push(item2.tetea_pra_ite_title)
            } else {
              error = "请填写第" + (index + 1) + "题正确答案"
              pass = false
            }
          })
          item.question_item = [{ tetea_pra_ite_code: "", tetea_pra_ite_isanswer: "10", tetea_pra_ite_title: tetea_pra_ite_title.toString() }]
        }
      })
      if (!pass) {
        this.$message({
          type: "info",
          duration: 2500,
          message: error
        });
      } else {
        this.submitQuestionDocload = true
        let params = {
          tetea_id: this.teteaId,
          questions: newsDoc
        };
        let res = await teach.$batchadd(params);
        if (res.status == 200) {
          this.$message({
            type: "success",
            duration: 1500,
            message: "试题已加入！",
          });
          this.showQuestionDoc = false
          this.getPractise();
        }
        this.submitQuestionDocload = false
      }
    },
    // 试卷切题完成请求
    async submitCuttingProblem(newsDoc){
      let params = {
        tetea_id: this.teteaId,
        qus_type:7,
        questions: newsDoc
      };
      this.loadingall=true
      let res = await $ocradd(params);
      if(res){
        this.showCutting=false;
        this.loadingall=false;
        this.getPractise();
        this.$message.success("试题添加成功");
      }else{
        this.loadingall=false;
      }
    },
  },
};
</script>
